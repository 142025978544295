import './loginModal.css';

import * as apis from '../../apis';

import { SnsLoginType, User } from '../../types';

import { KakaoIcon } from '../../utils/Icons';
// import KakaoLogin from 'react-kakao-login';
import KakaoLogin from '../shared/KakaoLogin/KakaoLogin';
import LoginButton from '../shared/LoginButton';
import Modal from 'react-modal';
import React from 'react';
import { colors } from '../../theme';
import styled from 'styled-components';

const customStyles = {
  overlay: {
    position: 'absolute',
    flex: 1,
    backgroundColor: 'rgba(0, 0, 0, 0.6)',
    // fontSize: 100,
  },
  content: {
    position: 'fixed',
    top: '100%',
    height: 250,
    borderTopRightRadius: 25,
    borderTopLeftRadius: 25,
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    borderWidth: 0,
    marginRight: '-11%',
    marginLeft: '-11%',
    padding: 0,
  },
};

interface Props {
  modalOnOff: boolean;
  modalOff: (success: boolean) => void;
}
const kakaoLoginKey = 'ee6d3a8341b76986ba628bf221754319';

Modal.setAppElement('#root');

const LoginModal = (props: Props): React.ReactElement => {
  const [loggingIn, setLoggingIn] = React.useState<boolean>(false);

  const successKakaoLogin = async (authObj): Promise<void> => {
    setLoggingIn(true);
    try {
      console.log(`Login Finished:${JSON.stringify(authObj)}`);
      const { response } = authObj;

      const customTokenResponse = await apis.simpleAuthGetCustomToken({
        platform: 'web',
        snsLoginType: SnsLoginType.kakao,
        kakaoAccessToken: authObj.response.access_token,
      });

      // const customTokenResponse = await apis.authGetKakaoFirebaseToken({
      //   kakaoAccessToken: authObj.response.access_token,
      //   platform: 'web',
      // });
      console.log('>> customTokenResponse = ', customTokenResponse);
      const { customToken } = customTokenResponse.data;
      await apis.signInWithCustomToken(customToken);
      props.modalOff(true);
    } catch (err) {
      console.error(err);
      props.modalOff(false);
    } finally {
      setLoggingIn(false);
    }
  };
  const failKakaoLogin = (err): void => {
    console.error(err);
  };

  return (
    <>
      <Modal
        isOpen={props.modalOnOff}
        // onAfterOpen={afterOpenModal}
        onRequestClose={(): void => {
          props.modalOff(false);
        }}
        style={customStyles}
        closeTimeoutMS={300}
        parentSelector={(): Element =>
          document.querySelector('#aftercalwrapper')
        }
        classNames="ReactModal__Content"
        overlayClassName="ReactModal__Overlay"
      >
        <div
          className="loginWrapper"
          style={{
            display: 'flex',
            flexDirection: 'column',
            padding: 20,
            paddingTop: 30,
            // paddingBottom: 30,
            fontFamily: 'Noto Sans KR',
            // border: '2px solid red',
            height: '80%',
            justifyContent: 'space-between',
          }}
        >
          <div className="titleWrapper">
            <div className="title" style={{ fontSize: 26, fontWeight: 700, marginBottom: 15, color: colors.black700 }}>
              {'로그인'}
            </div>
            <div className="subTitle" style={{ fontSize: 14, fontWeight: 300, color: colors.black400 }}>
              {'해당 서비스는 로그인이 필요합니다.'}
            </div>
          </div>
          <KakaoLogin
            jsKey={kakaoLoginKey}
            onSuccess={successKakaoLogin}
            onFailure={failKakaoLogin}
            render={(props): React.ReactElement => {
              return (
                <LoginButton
                  snsType={SnsLoginType.kakao}
                  onClick={(): void => {
                    props.onClick();
                  }}
                  // isDisabled={true}
                  isLoading={loggingIn}
                  // style={{ marginTop: 18 }}
                />
              );
            }}
          />
        </div>
      </Modal>
    </>
  );
};

export default LoginModal;
