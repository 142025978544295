import * as apis from '../../apis';

import { AdjustIcon, GuideIcon } from '../../utils/Icons';
import React, { useState } from 'react';
import { Redirect, useHistory, useLocation } from 'react-router-dom';

import Button from '../shared/Button';
import DesktopWrapper from '../shared/DesktopWrapper';
import { EstimatedMoneyDetailInfo } from '../../types';
import HomeLink from '../shared/HomeLink';
import LoginModal from '../shared/LoginModal';
import SagoCareCard from '../shared/SagoCareCard';
import SagoCareInfoInputButton from '../shared/SagoCareInfoInputButton';
import SignInOut from '../shared/SignInOut';
import { colors } from '../../theme';
import { isBrowser } from 'react-device-detect';
import numeral from 'numeral';
import styled from 'styled-components/native';
import { useAppContext } from '../../providers/AppProvider';
import { useGlobalLoadingContext } from '../../providers/GlobalLoadingProvider';
import { useMediaQuery } from 'react-responsive';

const Container = styled.View`
  flex: 1;
  background-color: #002d91;
  align-items: center;
  justify-content: center;
  border-width: 0;
  border-color: yellow;
  overflow: 'hidden';
`;

const MobileWrapper = styled.View`
  flex-grow: ${isBrowser ? 0 : 1};
  flex-shrink: ${isBrowser ? 0 : 1};
  flex-basis: ${isBrowser ? 'auto' : 0};
  width: ${isBrowser ? 360 : window.innerWidth};
  height: ${isBrowser ? 720 : window.innerHeight};
  overflow: hidden;
  border-width: 0;
  border-color: red;
`;

const MobileScrollView = styled.ScrollView`
  background-color: ${colors.white};
  width: 100%;
  height: 100%;
`;

const Wrapper = styled.View`
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  border-width: 0;
  border-color: red;
  padding-left: 20;
  padding-right: 20;
`;

const StyledText = styled.Text`
  font-family: Noto Sans KR;
  font-weight: 500;
  font-size: 14;
  line-height: 20;
  color: ${colors.black700};
`;

const StyledTitle = styled.Text`
  font-family: Noto Sans KR;
  font-weight: 700;
  font-size: 18;
  line-height: 23;
  color: ${colors.black700};
`;

const HorizontalBar = styled.View`
  background-color: ${colors.black100};
  width: 100%;
  height: 6px;
`;

const InfoBox = styled.View`
  background-color: ${colors.black100};
  width: 100%;
  padding: 20px;
`;

const InfoText = styled.Text`
  font-family: Noto Sans KR;
  font-size: 12;
  line-height: 17;
  color: ${colors.black400};
`;

const StyledTextMoney = styled.Text`
  font-family: Do Hyeon;
  font-size: 44;
  color: ${colors.black700};
`;

const MoneyWrapper = styled.View`
  flex-direction: row;
  align-items: center;
  border-width: 0;
  border-color: red;
`;

interface LocationState {
  estimatedMoney: number;
  estimatedMoneyDetailInfo: EstimatedMoneyDetailInfo;
  loggedId: string;
}
function SettlementMoneyAfterCalculateMobile(): React.ReactElement {
  const history = useHistory();
  const { state: { user } } = useAppContext();
  const location = useLocation() as {state: LocationState};
  const { state: { globalLoading } } = useGlobalLoadingContext();

  const paramEstimatedMoneyValue = location?.state?.estimatedMoney;
  const paramEstimatedMoneyDetailInfo = location?.state?.estimatedMoneyDetailInfo;
  const loggedId = location.state?.loggedId;

  const [transfering, setTransfering] = React.useState<boolean>(false);

  if (!paramEstimatedMoneyValue || !paramEstimatedMoneyDetailInfo) {
    alert('합의금 계산을 먼저 해주세요');
    return <Redirect to="/" />;
  }

  const [buttonPressed, setButtonPressed] = useState<boolean>(false);// 앱으로 전송하기 버튼 누름 여부

  const [modalOnOff, setModalOnOff] = React.useState(false);
  const modalOff = (success = false): void => {
    console.log('>> 모달 닫기 ', !!success);
    if (success) { // 로그인 처리 후 클로즈

    } else {
      setButtonPressed(false);
    }
    setModalOnOff(false);
  };

  const transferEstimatedMoneyData = async (loggedId): Promise<void> => {
    let resultMsg = '';
    try {
      await apis.transferEstimatedMoneyData(loggedId);
      resultMsg = '전송을 성공적으로 완료했습니다!';
    } catch (err) {
      console.log('>>> 전송 결과 오류 = ', err.message);

      resultMsg = err.message === 'CareOnoing' ? '사고케어 서비스가 이미 진행중입니다. 앱에서 다시 계산하기를 해주세요.'
        : err.message === 'RequestConnectAdjusterForm' ? '손해사정사 연결 신청 정보가 있어 전송이 되지 않았습니다. 앱에서 다시 계산하기를 해주세요.'
          : '';
    } finally {
      setTransfering(false);
      const location: Record<string, any> = {
        pathname: '/complete',
        state: {
          resultMsg,
        },
      };
      history.replace(location);
    }
  };

  const gotoAppLink = () : void => {
    if (isBrowser) {
      alert('모바일에서 실행해 주세요.');
    } else {
      window.location.assign('https://sagolinkapp.page.link/applink');
    }
  };

  React.useEffect(() => {
    console.log('<>>> uid = ', user.uid, buttonPressed);
    if (buttonPressed && !user.uid) { // 미로그인&&버튼눌러짐 => 로그인창
      setModalOnOff(true);
    }
    if (buttonPressed && !!user.uid) { // 로그인 && 버튼누름 => 전송하기
      console.log('>> 전송하기;;', loggedId);
      if (!loggedId) {
        alert('합의금 계산 데이터 처리 오류');
      } else {
        setTransfering(true);
        transferEstimatedMoneyData(loggedId);
        setButtonPressed(false);
      }
    }
  }, [buttonPressed, user.uid]);
  return (
    <>
      <MobileWrapper nativeID="aftercalwrapper">
        <MobileScrollView
          showsVerticalScrollIndicator={false}
          nestedScrollEnabled={false}
        >
          <Wrapper style={{ marginTop: 30 }}>
            <StyledText>{'나의 예상 합의금'}</StyledText>
            <MoneyWrapper>
              <StyledTextMoney style={{ marginTop: 3, fontWeight: 'bold' }}>
                {paramEstimatedMoneyDetailInfo?.recommendAdjuster
                  ? numeral(5000000).format('0,0')
                  : numeral(paramEstimatedMoneyValue).format('0,0')}
              </StyledTextMoney>
              <StyledText style={{ fontSize: 24, marginLeft: 3, fontWeight: 'bold', top: 5 }}>
                {paramEstimatedMoneyDetailInfo?.recommendAdjuster
                  ? '원 이상'
                  : '원'}
              </StyledText>
            </MoneyWrapper>
            {paramEstimatedMoneyDetailInfo.recommendAdjuster ? (
              <StyledText
                style={{
                  fontSize: 18,
                  lineHeight: 24,
                  fontWeight: 'bold',
                  marginLeft: 3,
                  marginTop: 10,
                }}
              >
                {'피해가 상당하여\n단순 합의금 계산이 어렵습니다.'}
              </StyledText>
            ) : null}
          </Wrapper>
          <Wrapper style={{ marginBottom: 20 }}>
            <SagoCareCard
              style={{ marginTop: 10 }}
              icon={
                paramEstimatedMoneyDetailInfo.recommendAdjuster
                  ? AdjustIcon
                  : GuideIcon
              }
              text={
                paramEstimatedMoneyDetailInfo.recommendAdjuster
                  ? '내 피해 정도를\n돈으로 환산하려면?'
                  : '내 합의금에 대해\n더 잘 알고 싶다면?'
              }
              onClick={gotoAppLink}
            />
          </Wrapper>
          <HorizontalBar />
          <Wrapper style={{ marginTop: 20, marginBottom: 78 }}>
            <StyledTitle>
              {'교통사고 치료,\n꼬박꼬박 받으셔야 해요.'}
            </StyledTitle>
            <SagoCareInfoInputButton
              style={{ marginTop: 20 }}
              text={'쉽고 간편하게\n자동차보험 병원 예약하려면?'}
              buttonText={'앱 설치하기'}
              onClick={gotoAppLink}
            />
          </Wrapper>
        </MobileScrollView>
        <InfoBox>
          <InfoText>
            {'사고링크는 변호사법 및 보험업법을 준수하기 위해 최선을 다해 노력하고 있습니다. ' +
              '사고링크의 합의금 계산 서비스는 고객이 작성한 내용을 토대로 자동차 보험 약관에 따라 산출하고 ' +
              '통계에 따른 정보를 제공합니다. 각 개별적인 권리분석이 아닌 일반적인 수준의 정보를 제공합니다. ' +
              '사고링크에서는 고객과 보험사간 협상에 전혀 개입하지 않으며, ' +
              '이와 관련된 모든 내용에 대해 어떠한 법적 책임이 없음을 고지합니다.'}
          </InfoText>
        </InfoBox>
        {/* <LoginModal
          modalOnOff={modalOnOff}
          modalOff={modalOff}
        /> */}
        {/* <LoginModal modalOnOff={modalOnOff} modalOff={modalOff} /> */}
        <Button
          isLoading={transfering}
          // activeOpacity={1}
          onClick={(): void => {
            // onConfirm();
            // navigation.navigate('SettlementMoneyAfterCalculate');
            // modalOn(); // 로그인팝업 등장
            console.log('>>>>> button clicked.. ');
            setButtonPressed(true);
          }}
          style={{
            width: '100%',
            height: 56,
            backgroundColor: colors.exitBlue,
            alignItems: 'center',
            justifyContent: 'center',
            bottom: 0,
            borderWidth: 0,
            borderRadius: 0,
            zIndex: 0,
          }}
          text={'앱으로 전송하기'}
          textStyle={{
            fontFamily: 'Noto Sans KR',
            color: colors.white,
            fontSize: 18,
          }}
        />
      </MobileWrapper>
      {/* <SampleModal modalOnOff={modalOnOff} modalOff={modalOff} /> */}
      <LoginModal modalOnOff={modalOnOff} modalOff={modalOff} />
    </>
  );
}

const SettlementMoneyAfterCalculate = (): React.ReactElement => {
  return (
    <Container>
      {isBrowser ? (
        <>
          <DesktopWrapper>
            <SettlementMoneyAfterCalculateMobile />
          </DesktopWrapper>
        </>
      ) : (
        <SettlementMoneyAfterCalculateMobile />
      )}
      {isBrowser ? <HomeLink /> : null}
      {isBrowser ? <SignInOut /> : null}
    </Container>
  );
};

export default SettlementMoneyAfterCalculate;
