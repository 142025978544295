import { AccidentCategoryType, AccidentForm, WoundedDetail, WoundedPart, WoundedPartType } from '../../../types';
import { Image, TouchableOpacity, View, ViewStyle } from 'react-native';
import { Selected, Unselected } from '../../../utils/Icons';
import { WoundedPartLargeImages, WoundedPartLine } from '../../../utils/DesignAssets';
import React from 'react';
import styled from 'styled-components/native';

const BodySelectButton = styled.TouchableOpacity`
  position: absolute;
  z-index: 2;
  border-width: 0;
  border-color: red;
  width: 30;
  height: 30;
`;

const BodySelectButtonImage = styled.Image`
  position: absolute;
  opacity: 0.5;
  width: 28;
  height: 28;
  z-index: 2;
`;

interface Props {
  onPressWoundedPart: (woundedPartType: WoundedPartType) => void; // 부상부위 선택
  selectedWoundedPartType?: WoundedPartType;
}

const AcciWoundedPartBody = (props: Props): React.ReactElement => {
  return (
    <View style={{ borderWidth: 0, borderColor: 'red' }}>
      <Image
        source={WoundedPartLargeImages.Body}
        style={{ width: 137, height: 288 }}
      />
      <SelectButton
        position={{ left: 40, top: -10 }}
        selected={props.selectedWoundedPartType === WoundedPartType.headNeck}
        onPress={(): void => {
          props.onPressWoundedPart(WoundedPartType.headNeck);
        }}
      />
      <SelectButton
        position={{ left: 85, top: 30 }}
        selected={props.selectedWoundedPartType === WoundedPartType.shoulderCollarbone}
        onPress={(): void => {
          props.onPressWoundedPart(WoundedPartType.shoulderCollarbone);
        }}
      />
      <SelectButton
        position={{ left: 40, top: 85 }}
        selected={props.selectedWoundedPartType === WoundedPartType.waistRib}
        onPress={(): void => {
          props.onPressWoundedPart(WoundedPartType.waistRib);
        }}
      />
      <SelectButton
        position={{ left: 105, top: 80 }}
        selected={props.selectedWoundedPartType === WoundedPartType.armElbow}
        onPress={(): void => {
          props.onPressWoundedPart(WoundedPartType.armElbow);
        }}
      />
      <SelectButton
        position={{ left: -5, top: 122 }}
        selected={props.selectedWoundedPartType === WoundedPartType.handWrist}
        onPress={(): void => {
          props.onPressWoundedPart(WoundedPartType.handWrist);
        }}
      />
      <SelectButton
        position={{ left: 80, top: 132 }}
        selected={props.selectedWoundedPartType === WoundedPartType.pelvis}
        onPress={(): void => {
          props.onPressWoundedPart(WoundedPartType.pelvis);
        }}
      />
      <SelectButton
        position={{ left: 20, top: 190 }}
        selected={props.selectedWoundedPartType === WoundedPartType.legKnee}
        onPress={(): void => {
          props.onPressWoundedPart(WoundedPartType.legKnee);
        }}
      />
      <SelectButton
        position={{ left: 92, top: 253 }}
        selected={props.selectedWoundedPartType === WoundedPartType.footAnkle}
        onPress={(): void => {
          props.onPressWoundedPart(WoundedPartType.footAnkle);
        }}
      />

      <View style={{ position: 'absolute', zIndex: 1 }}>
        {props.selectedWoundedPartType ? (
          <Image
            source={WoundedPartLargeImages[props.selectedWoundedPartType]}
            style={{ width: 137, height: 288 }}
          />
        ) : null}
      </View>

      {/*
      {props.selectedWoundedPart === WoundedPartType.headNeck ? (
        <View style={{ position: 'absolute', top: 19 }}>
          <Image source={WoundedPartLine.headNeck} style={{ width: 61, height: 18 }} />
        </View>) : null }
      {props.selectedWoundedPart === WoundedPartType.waistRib ? (
        <View style={{ position: 'absolute', top: 68 }}>
          <Image source={WoundedPartLine.waistRib} style={{ width: 47, height: 34 }} />
        </View>) : null }
      {props.selectedWoundedPart === WoundedPartType.shoulderCollarbone ? (
        <View style={{ position: 'absolute', top: 19, left: 90 }}>
          <Image source={WoundedPartLine.shoulderCollarbone} style={{ width: 47, height: 31 }} />
        </View>) : null }
      {props.selectedWoundedPart === WoundedPartType.armElbow ? (
        <View style={{ position: 'absolute', top: 62, left: 115 }}>
          <Image source={WoundedPartLine.armElbow} style={{ width: 22, height: 28 }} />
        </View>) : null }
      {props.selectedWoundedPart === WoundedPartType.pelvis ? (
        <View style={{ position: 'absolute', top: 136 }}>
          <Image source={WoundedPartLine.pelvis} style={{ width: 43, height: 74 }} />
        </View>) : null }
      {props.selectedWoundedPart === WoundedPartType.legKnee ? (
        <View style={{ position: 'absolute', top: 204 }}>
          <Image source={WoundedPartLine.legKnee} style={{ width: 40, height: 58 }} />
        </View>) : null }

      {props.selectedWoundedPart === WoundedPartType.handWrist ? (
        <View style={{ position: 'absolute', top: 157, left: 131 }}>
          <Image source={WoundedPartLine.handWrist} style={{ width: 7, height: 52 }} />
        </View>) : null }
      {props.selectedWoundedPart === WoundedPartType.footAnkle ? (
        <View style={{ position: 'absolute', top: 260, left: 98 }}>
          <Image source={WoundedPartLine.footAnkle} style={{ width: 40, height: 3 }} />
        </View>) : null }

      <View style={{ position: 'absolute', zIndex: 1 }}>
        {props.selectedWoundedPart ? (
          <Image
            source={WoundedPartLargeImages[props.selectedWoundedPart]}
            style={{ width: 137, height: 288 }} />) : null}
      </View> */}
    </View>
  );
};

export default AcciWoundedPartBody;

interface WoundedPartSelectButtonProps {
  position: {left: number; top: number};
  selected: boolean;
  onPress: () => void;
}
const SelectButton = (props: WoundedPartSelectButtonProps): React.ReactElement => {
  return (
    <TouchableOpacity
      activeOpacity={1}
      style={{ position: 'absolute', zIndex: 2, width: 30, height: 30, ...props.position }}
      onPress={props.onPress}>
      <BodySelectButtonImage source={props.selected ? Selected : Unselected} />
    </TouchableOpacity>
  );
};
