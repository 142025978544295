import { AccidentCategoryType, AccidentForm } from '../../../types';
import Select, {
  ControlProps,
  GroupProps,
  InputProps,
  ValueContainerProps,
  components,
} from 'react-select';

import React from 'react';
import { colors } from '../../../theme';
import { useAppContext } from '../../../providers/AppProvider';

const customStyles = {
  controlStyles: {
    borderRadius: '1px solid black',
    padding: '0px',
    // background: 'green',
    // color: 'white',
  },
};

const CustomControl = (props: ControlProps<any>): React.ReactElement => {
  return (
    <div style={customStyles.controlStyles}>
      <components.Control {...props} />
    </div>
  );
};

const CustomInput = (props: InputProps): React.ReactElement => {
  return <components.Input {...props} isHidden={true} />;
};

const CustomValueContainer = (
  props: ValueContainerProps<any>,
): React.ReactElement => {
  return (
    <components.ValueContainer {...props}>
      {props.children}
    </components.ValueContainer>
  );
};

const CustomSelectContainer = (props: any): React.ReactElement => {
  return (
    <components.SelectContainer {...props}>
      {props.children}
    </components.SelectContainer>
  );
};

const NoOptionsMessage = (props) => {
  return (
    <components.NoOptionsMessage {...props} />
  );
};

interface Props {
  testID?: string;
  title?: string;
  isDisabled?: boolean;
  onClick?: () => void;
  onPress?: () => void;
  accidentCategoryType?: AccidentCategoryType;
  onSelectAccidentForm: (accidentForm: AccidentForm) => void;
  selectRef: any;
}

const AccidentFormSelect = (props: Props): React.ReactElement => {
  const [selectedOption, setSelectedOption] = React.useState(null);
  const handleChange = (selectedOption): void => {
    setSelectedOption({ selectedOption });
  };

  const selectRef = React.useRef<Select>();
  const [menuIsOpen, setMenuIsOpen] = React.useState<boolean>();

  const [pickerVisible, setPickerVisible] = React.useState(true);
  const [value, setValue] = React.useState('사고 형태를 선택해주세요');
  const {
    state: { baseAccidentForms },
  } = useAppContext();
  const [items, setItems] = React.useState<AccidentForm[]>([]);

  React.useEffect(() => {
    const accidentForms: AccidentForm[] = Object.values(baseAccidentForms);
    const selectItems = accidentForms.filter((item, index) => {
      return item.accidentCategoryType === props.accidentCategoryType;
    });
    setItems(selectItems);
    console.log(items);
  }, [props.accidentCategoryType]);

  return (
    <>
      <div
        style={{
          // border: '1px solid red',
          padding: 0,
          width: '100%',
        }}
        id="selectorWrapper"
      >
        <Select
          value={value}
          onChange={(newValue: any, actionMeta: any):void => {
            setValue(newValue);
            // console.log('>>>> selected.. ', index, items[index - 1]);
            props.onSelectAccidentForm(newValue);
          }}
          isSearchable={false}
          // menuIsOpen={menuIsOpen}
          // captureMenuScroll
          // onBlur={(event):void => event.preventDefault()}
          // closeMenuOnScroll={():boolean => true}
          menuShouldBlockScroll={true}
          // maxMenuHeight={300}
          // minMenuHeight={200}
          // captureMenuScroll={true}
          focusDefaultOption={false}
          openMenuOnFocus={true}
          menuShouldPortal={false}
          menuPlacement="auto"
          menuPosition="absolute"
          // autoFocus={false}
          ref={props.selectRef}
          placeholder="사고 형태를 선택해주세요."
          styles={{
            singleValue: (base): React.CSSProperties => ({
              ...base,
              color: colors.black400,
              fontSize: 14,
              fontWeight: 500,
            }),
            // valueContainer: (base): React.CSSProperties => ({
            //   ...base,
            //   color: 'white',
            //   width: '100%',
            // }),
            container: (base): React.CSSProperties => ({
              ...base,
              padding: 0,
            }),
            control: (base): React.CSSProperties => {
              const hoverStyle = {
                '&:hover': {
                  // display: 'none',
                  borderColor: 'transparent',
                },
              } as React.CSSProperties;
              return {
                ...base,
                ...hoverStyle,
                backgroundColor: colors.black100,
                borderRadius: '2px',
                padding: 3,
                color: colors.black700,
                fontSize: 14,
                border: '0px solid lightgray',
                boxShadow: 'none', // no box-shadow
              };
            },
            indicatorSeparator: (base): React.CSSProperties => ({
              ...base,
              backgroundColor: 'transparent',
              // padding: 5,
            }),
            menu: (base): React.CSSProperties => {
              const { zIndex, ...rest } = base;
              return {
                ...base,
                width: '100%',
                // overflow: 'hidden',
                // backgroundColor: colors.black200,
                // padding: 3,
                // border: '1px solid red',
                // zIndex: 9999,
                // overflow: 'scroll',
              };
            },
            // placeholder: (base): React.CSSProperties => ({
            //   ...base,
            //   color: colors.black400,
            //   fontSize: 14,
            // }),
            option: (base): React.CSSProperties => ({
              ...base,
              color: colors.black700,
              fontSize: 12,
              // height: 13,
              // lineHeight: 12,
              // border: '1px solid purple',
              // zIndex: 9999,
            }),
            menuPortal: (base): React.CSSProperties => ({
              ...base,
              // position: 'absolute',
              zIndex: 9999,
              // border: '1px solid red',
              // backgroundColor: colors.black700,
            }),
            menuList: (base): React.CSSProperties => {
              const webkitStyle = {
                '::-webkit-scrollbar': {
                  // display: 'none',
                  width: '3px',
                  height: '3px',
                  colors: 'red',
                },
              } as React.CSSProperties;
              return {
                ...base,
                padding: 0,
                scrollbarColor: 'red',
                // ...webkitStyle,
                // border: '5px solid blue',
                // zIndex: 9999,
                // flex: 1,
                // height: 200,
              };
            },
            noOptionsMessage: (base): React.CSSProperties => ({
              ...base,
              // position: 'absolute',
              fontSize: 14,
              // border: '1px solid red',
              // backgroundColor: colors.black700,
            }),
          }}
          // isClearable
          components={{
            Control: CustomControl,
            ValueContainer: CustomValueContainer,
            SelectContainer: CustomSelectContainer,
            Input: CustomInput,
            NoOptionsMessage,
          }}
          // isSearchable
          name="color"
          options={items}
          menuPortalTarget={document.body}
          noOptionsMessage={():string => '상단의 사고 유형을 먼저 선택해주세요'}
        />
      </div>
    </>
  );
};

export default AccidentFormSelect;
