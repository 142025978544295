import { BrowserRouter, Route, Switch } from 'react-router-dom';
import React, { ReactElement } from 'react';

import SettlementMoney from '../screen/SettlementMoney';
import SettlementMoneyAfterCalculate from '../screen/SettlementMoneyAfterCalculate';
import SettlementMoneyTransmissionComplete from '../screen/SettlementMoneyTransmissionComplete';

function SwitchNavigator(): ReactElement {
  return (
    <BrowserRouter>
      {/* <div
        style={{
          textAlign: 'center'
        }}
      > */}
      <Switch>
        <Route exact={true} path="/" render={(): ReactElement => <SettlementMoney />} />
        <Route exact={true} path="/result" render={(): ReactElement => <SettlementMoneyAfterCalculate />} />
        <Route exact={true} path="/complete" render={(): ReactElement => <SettlementMoneyTransmissionComplete />} />
      </Switch>
      {/* </div> */}
    </BrowserRouter>
  );
}

export default SwitchNavigator;
