import {
  EstimatedMoneyDetailInfo,
  SettlementMoneyInputData, User,
  WoundedDetail, initialSettlementMoneyInputData,
} from '../types';
import React, { useReducer } from 'react';

/**
 * UserStore 전역변수 관리 Provider
 * > settlementMoney
 */
import createCtx from '../utils/createCtx';
import produce from 'immer';

interface Context {
  state: State;
  resetSagocareData: () => void; // 사고케어 관련 데이터 초기화 (예상합의금, 입력데이터, 사고케어 진행중 여부 등등.. 모두 다 리셋)
  updateSettlementMoneyInputData: (settlementMoneyInputData: SettlementMoneyInputData) => void; // 예상합의금 관련 정보 업데이트
  updateCareOngoing: (careOngoing: boolean) => void; // 사고케어 서비스 진행중 여부 업데이트
  // resetSettlementMoneyInputData: () => void; // 예상합의금 관련 정보 초기화
  updateEstimatedMoney: (estimatedMoney: number | undefined) => void; // 예상합의금 계산 수신 정보 업데이트
  updateEstimatedMoneyDetailInfo: (estimatedMoneyDetailInfo: EstimatedMoneyDetailInfo) => void;
  addWoundedDetail: (woundedDetail: WoundedDetail) => void; // 부상부위 상세 데이터 추가
  removeWoundedDetail: (woundedDetail: WoundedDetail) => void; // 부상부위 상세 데이터 삭제
  setShowSagocareStartAlert: (isShow) => void; // 사고케어 시작 알림 팝업 표시하기
}
const [useCtx, Provider] = createCtx<Context>();

export enum ActionType {
  SetUserStore = 'set-userstore',
  UpdateSettlementMoneyInputData = 'update-settlement-money-input-data',
  UpdateCareOngoing = 'update-care-ongoing',
  ResetSagocareData = 'reset-sagocare-data',
  // ResetSettlementMoneyInputData = 'reset-settlement-money-input-data',
  UpdateEstimatedMoney = 'update-estimated-money',
  UpdateEstimatedMoneyDetailInfo = 'update-estimated-money-detail-info',
  AddWoundedDetail = 'add-wounded-detail',
  RemoveWoundedDetail = 'remove-wounded-detail',
  SetShowSagocareStartAlert = 'set-show-sagocare-start-alert',
}

export interface State {
  showSagocareStartAlert: boolean; // 사고케어 시작 알림 팝업 표시 여부
  settlementMoneyInputData: SettlementMoneyInputData; // 예상합의금 관련 정보
  estimatedMoney?: number; // 계산된 예상합의금
  // calculated: boolean; // 예상합의금 계산 여부
  careOngoing: boolean; // 케어서비스 진행 중 여부
  estimatedMoneyDetailInfo?: EstimatedMoneyDetailInfo; // 예상합의금 계산 상세 정보
}

// 사고케어 스토어 초기값
const initialState: State = {
  showSagocareStartAlert: false,
  settlementMoneyInputData: initialSettlementMoneyInputData,
  estimatedMoney: undefined,
  estimatedMoneyDetailInfo: undefined,
  // calculated: false,
  careOngoing: false,
};

// ====== 예상합의금 액션
interface UpdateSettlementMoneyInputDataAction { // 입력 데이터 업데이트
  type: ActionType.UpdateSettlementMoneyInputData;
  payload: SettlementMoneyInputData;
}
interface ResetSagocareDataAction { // 입력 데이터 리셋
  type: ActionType.ResetSagocareData;
}
interface UpdateCareOngoingAction { // 케어 서비스 진행중 여부 업데이트
  type: ActionType.UpdateCareOngoing;
  payload: boolean;
}
interface UpdateEstimatedMoneyAction { // 예상합의금 업데이트
  type: ActionType.UpdateEstimatedMoney;
  payload: number;
}
interface UpdateEstimatedMoneyDetailInfoAction { // 예상합의금 계산 상세 정보 업데이트
  type: ActionType.UpdateEstimatedMoneyDetailInfo;
  payload: EstimatedMoneyDetailInfo;
}
interface AddWoundedDetailAction { // 부상부위 상세 정보 추가
  type: ActionType.AddWoundedDetail;
  payload: WoundedDetail;
}
interface RemoveWoundedDetailAction { // 부상부위 상세 정보 제거
  type: ActionType.RemoveWoundedDetail;
  payload: WoundedDetail;
}
interface SetShowSagocareStartAlertAction { // 사고케어 시작 알림 표시
  type: ActionType.SetShowSagocareStartAlert;
  payload: boolean;
}
// ======= 다른 액션

type Action = UpdateSettlementMoneyInputDataAction | ResetSagocareDataAction | UpdateCareOngoingAction |
UpdateEstimatedMoneyAction | UpdateEstimatedMoneyDetailInfoAction | AddWoundedDetailAction | RemoveWoundedDetailAction|
SetShowSagocareStartAlertAction;

interface Props {
  children: React.ReactNode;
}

type Reducer = (state: State, action: Action) => State;

// DB만 업데이트, 리스너에서 state를 업데이트 하게 됨. off line인 경우는 지연? 작동함
// const updateSettlementMoneyInfo = (uid: string) => (settlementMoneyInfo: SettlementMoneyInfo): void => {
//   firebase.firestore().collection('UserStore').doc(uid).set({
//     settlementMoneyInfo: settlementMoneyInfo,
//   });
// };
const updateSettlementMoneyInputData = (dispatch: React.Dispatch<UpdateSettlementMoneyInputDataAction>) => (
  settlementMoneyInputData: SettlementMoneyInputData,
): void => {
  dispatch({
    type: ActionType.UpdateSettlementMoneyInputData,
    payload: settlementMoneyInputData,
  });
};
const resetSagocareData = (dispatch: React.Dispatch<ResetSagocareDataAction>) => (): void => {
  dispatch({
    type: ActionType.ResetSagocareData,
  });
};
const updateEstimatedMoney = (dispatch: React.Dispatch<UpdateEstimatedMoneyAction>) => (
  estimatedMoney,
): void => {
  dispatch({
    type: ActionType.UpdateEstimatedMoney,
    payload: estimatedMoney,
  });
};
const updateEstimatedMoneyDetailInfo = (dispatch: React.Dispatch<UpdateEstimatedMoneyDetailInfoAction>) => (
  estimatedMoneyDetailInfo: EstimatedMoneyDetailInfo,
): void => {
  console.log('=================== updateEstimatedMoneyDetailInfo == ', estimatedMoneyDetailInfo);
  dispatch({
    type: ActionType.UpdateEstimatedMoneyDetailInfo,
    payload: estimatedMoneyDetailInfo,
  });
};
const updateCareOngoing = (dispatch: React.Dispatch<UpdateCareOngoingAction>) => (
  careOngoing: boolean,
): void => {
  dispatch({
    type: ActionType.UpdateCareOngoing,
    payload: careOngoing,
  });
};
const addWoundedDetail = (dispatch: React.Dispatch<AddWoundedDetailAction>) => (
  woundedDetail: WoundedDetail,
): void => {
  dispatch({
    type: ActionType.AddWoundedDetail,
    payload: woundedDetail,
  });
};
const removeWoundedDetail = (dispatch: React.Dispatch<RemoveWoundedDetailAction>) => (
  woundedDetail: WoundedDetail,
): void => {
  dispatch({
    type: ActionType.RemoveWoundedDetail,
    payload: woundedDetail,
  });
};
const setShowSagocareStartAlert = (dispatch: React.Dispatch<SetShowSagocareStartAlertAction>) => (
  isShow: boolean,
): void => {
  dispatch({
    type: ActionType.SetShowSagocareStartAlert,
    payload: isShow,
  });
};
const reducer: Reducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionType.UpdateSettlementMoneyInputData:
      return { ...state, settlementMoneyInputData: action.payload };
    case ActionType.ResetSagocareData:
      return initialState;
      // return { ...state, settlementMoneyInputData: initialSettlementMoneyInputData };
    case ActionType.UpdateEstimatedMoney:
      return { ...state, estimatedMoney: action.payload };
    case ActionType.UpdateEstimatedMoneyDetailInfo:
      return { ...state, estimatedMoneyDetailInfo: action.payload };
    case ActionType.UpdateCareOngoing:
      return { ...state, careOngoing: action.payload };
    case ActionType.AddWoundedDetail:
      return produce(state, (draft) => {
        const woundedDetails = draft.settlementMoneyInputData.woundedDetails;
        if (!woundedDetails.find((woundedDetail) => woundedDetail.id === action.payload.id)) { // 없어야 추가
          const index = draft.settlementMoneyInputData.woundedDetails.length;
          draft.settlementMoneyInputData.woundedDetails.splice(index, 0, action.payload); // 끝에 추가
        }
      });
    case ActionType.RemoveWoundedDetail:
      return produce(state, (draft) => {
        const index = draft.settlementMoneyInputData.woundedDetails.findIndex(
          (woundedDetail) => woundedDetail.id === action.payload.id,
        );
        if (index !== -1) {
          draft.settlementMoneyInputData.woundedDetails.splice(index, 1); // 한개 삭제
        }
      });
    case ActionType.SetShowSagocareStartAlert:
      return { ...state, showSagocareStartAlert: action.payload };
    default:
      return state;
  }
};

function SagocareProvider(props: Props): React.ReactElement {
  const [state, dispatch] = useReducer<Reducer>(reducer, initialState);
  const actions = {
    updateSettlementMoneyInputData: updateSettlementMoneyInputData(dispatch),
    resetSagocareData: resetSagocareData(dispatch),
    // resetSettlementMoneyInputData: resetSettlementMoneyInputData(dispatch),
    updateEstimatedMoney: updateEstimatedMoney(dispatch),
    updateEstimatedMoneyDetailInfo: updateEstimatedMoneyDetailInfo(dispatch),
    addWoundedDetail: addWoundedDetail(dispatch),
    removeWoundedDetail: removeWoundedDetail(dispatch),
    updateCareOngoing: updateCareOngoing(dispatch),
    setShowSagocareStartAlert: setShowSagocareStartAlert(dispatch),
  };

  return <Provider value={{ state, ...actions }}>{props.children}</Provider>;
}

export { useCtx as useSagocareContext, SagocareProvider };
