import {
  Adjuster,
  Coord,
  EstimatedMoneyDetailInfo,
  HospitalBookingInfo,
  PlatformType,
  ReceivedCaretipData, RequestConnectAdjusterForm, SettlementMoneyInputData,
} from '../types';
import { androidConfig, iosConfig } from '../../config';

import DeviceInfo from 'react-native-device-info';
import { Platform } from 'react-native';
// import '@react-native-firebase/functions';
import firebase from 'firebase';

// import '@react-native-firebase/auth';

// const firestore = firebase.firestore();
if (!firebase.apps.length) {
  firebase.initializeApp(Platform.OS === 'ios' ? iosConfig : androidConfig);
}

function sleep(ms = 0): Promise<void> {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

// 인증코드 요청
export const requestConfirmCode = firebase
  .app()
  .functions('asia-northeast1')
  .httpsCallable('requestConfirmCode');
// 인증코드 검증
export const verifyConfirmCode = firebase
  .app()
  .functions('asia-northeast1')
  .httpsCallable('verifyConfirmCode');
// 회원가입
export const authSignup = firebase
  .app()
  .functions('asia-northeast1')
  .httpsCallable('authSignup');
// export const signup = firebase
//   .app()
//   .functions('asia-northeast1')
//   .httpsCallable('signup');
// 커스텀토큰으로 로그인 처리하기
export const signInWithCustomToken = async (customToken: string): Promise<void> => {
  console.log('>>>> signin with customToken ');
  try {
    await firebase.auth().setPersistence(firebase.auth.Auth.Persistence.SESSION);
    await firebase.auth().signInWithCustomToken(customToken); // 회원가입 완료 되었으므로 로그인 처리
  } catch (error) {
    // Handle Errors here.
    const errorCode = error.code;
    const errorMessage = error.message;
  }
};

// 파베 토큰 요청 (kakao, apple 공통)
export const simpleAuthGetCustomToken = firebase.app().functions('asia-northeast1')
  .httpsCallable('simpleAuth_GetCustomToken');

// 카카오 파베 토큰 요청
// export const authGetKakaoFirebaseToken = firebase.app().functions('asia-northeast1')
//   .httpsCallable('authGetKakaoFirebaseToken');

// 애플 파베 토큰 요청
// export const authGetAppleFirebaseToken = firebase.app().functions('asia-northeast1')
//   .httpsCallable('authGetAppleFirebaseToken');

// 병원 예약하기
export const requestBookHospital = async (bookInfo: HospitalBookingInfo): Promise<any> => {
  const fn = firebase.app().functions('asia-northeast1').httpsCallable('requestBookHospital');
  try {
    const response = await fn({
      bookInfo: {
        ...bookInfo,
        bookDate: bookInfo.bookDate,
      },
    });
    return response;
  } catch (error) {
    console.log('>>> error', JSON.stringify(error.code));
    throw error;
  }
};

/**
 * 손해사정사 fetch
 * > 손해사정사 화면에서 손사 목록을 보여주는 것은 로그인 한 유저로 한다.
 * > 합의금 계산 중에 중중상을 선택하면 손사쪽 가는 권유는 유지
 * 중중상 선택시에만 손사를 보여주는 제약조건은 그 방법이 너무 약해서 (아무나 할 수 있어서) 차후에 다른 방법을 찾기로 함
 * 앱 초기에 손사를 오픈하는 것이 마케팅 측면에서 유리할 것으로 판단
 * 향후 손사쪽에서 문제 제기 등이 있는 경우 기능 변경 검토
 */
export const fetchAdjusters = async (devicePosition?: Coord): Promise<Adjuster[]> => {
  console.log('>>>> fetchAjusters devicePosition = ', devicePosition);

  const fn = firebase.app().functions('asia-northeast1').httpsCallable('fetchAdjusters');
  const response = await fn({ devicePosition });
  return response.data;
};

// 예상합의금 계산중 상태 요청
export const requestEstimatedMoneyStatus = async (
  settlementMoneyInputData: SettlementMoneyInputData,
): Promise<any> => {
  const fn = firebase.app().functions('asia-northeast1').httpsCallable('requestEstimatedMoneyStatus');
  const response = await fn({
    settlementMoneyInputData: {
      ...settlementMoneyInputData,
    },
  });
  return response.data;
};

// 예상합의금 계산 요청
export const requestEstimatedMoney = async (
  settlementMoneyInputData: SettlementMoneyInputData, force = false,
): Promise<any> => {
  const fn = firebase.app().functions('asia-northeast1').httpsCallable('requestEstimatedMoney');
  const response = await fn({
    settlementMoneyInputData: {
      ...settlementMoneyInputData,
    },
    force,
    platformType: PlatformType.WEB,
  });
  return response.data;
};

// 사고케어 등록
export const registCareOngoing = async (
): Promise<any> => {
  try {
    const fn = firebase.app().functions('asia-northeast1').httpsCallable('registCareOngoing');
    const response = await fn({});
    return response.data;
  } catch (err) {
    console.warn('error at registCareOngoing', err);
    return false;
  }
};

// 사고케어 (예상합의금, 케어서비스 등) 초기화
export const resetSagocare = async (
): Promise<any> => {
  const fn = firebase.app().functions('asia-northeast1').httpsCallable('resetSagocare');
  const response = await fn({});
  return response.data;
};

// 손해사정사 연결 신청
export const requestConnectAdjuster = async (
  requestConnectAdjusterForm: RequestConnectAdjusterForm,
  settlementMoneyInputData: SettlementMoneyInputData,
): Promise<any> => {
  const fn = firebase.app().functions('asia-northeast1').httpsCallable('requestConnectAdjuster');
  const response = await fn({ requestConnectAdjusterForm, settlementMoneyInputData });
  console.log('response of requestConnectAdjuster = ', response);
  return response.data;
};

// FCM 토큰을 서버에 저장하기
export const updateFcmTokenToServer = async (userId: string, FcmToken: string): Promise<any> => {
  const fn = firebase.app().functions('asia-northeast1').httpsCallable('updateFcmToken');
  const response = await fn({ FcmToken: FcmToken });
  console.log('response of updateFcmToken = ', response);
  // return response.data;
  // await firestore().collection('FCMUsers').doc(userId).set({
  //   FcmTokens: firestore.FieldValue.arrayUnion(FcmToken),
  // }, { merge: true });
};

// Device version 서버에 저장하기
export const updateLastDeviceInfo = async (): Promise<any> => {
  const version = DeviceInfo.getVersion();
  const systemName = DeviceInfo.getSystemName(); // iOS: "iOS" on newer iOS devices "iPhone OS" on older devices, including older iPad's. // Android: "Android"
  const systemVersion = DeviceInfo.getSystemVersion(); // iOS: "11.0" // Android: "7.1.1"
  const isTablet = DeviceInfo.isTablet();
  const deviceInfo = {
    appVersion: version,
    systemName,
    systemVersion,
    isTablet,
  };
  const fn = firebase.app().functions('asia-northeast1').httpsCallable('updateLastDeviceInfo');
  const response = await fn({ deviceInfo: deviceInfo });
  console.log('response of updateLastDeviceInfo = ', response);
};

// 수신한 caretip Data 가져오기
// export const fetchCaretipDatas = async (uid: string): Promise<any[]> => {
//   // const fn = firebase.app().functions('asia-northeast1').httpsCallable('fetchCaretipDatas');
//   // const response = await fn({});
//   // return response.data;

//   const snap = await firestore().collection('UserStore').doc(uid).get();
//   const userData = snap.data();
//   const receivedCaretipData = userData && userData.receivedCaretipData;
//   return receivedCaretipData;
//   // console.log('>>>> fetchHospitals 2.. apis.. ', snaps.docs.length);
// };
// 수신한 케어팁 데이터 읽음 처리
export const setReadReceivedCaretipData = async (receivedCaretipData: ReceivedCaretipData): Promise<any> => {
  const fn = firebase.app().functions('asia-northeast1').httpsCallable('setReadReceivedCaretipData');
  const response = await fn({ receivedCaretipData });
  return response;
};
// 전체 저장된 케어팁 읽음 처리 : 케어팁 스크린에서 호출하는 것으로 변경함
export const setReadPresetCaretipData = async (caretipId: string): Promise<any> => {
  const fn = firebase.app().functions('asia-northeast1').httpsCallable('setReadPresetCaretipData');
  const response = await fn({ caretipId });
  return response;
};

// 사고케어 진행율(달성율) 구하기
export const getSagocareGoalRate = async (): Promise<any> => {
  const fn = firebase.app().functions('asia-northeast1').httpsCallable('getSagocareGoalRate');
  const response = await fn({});
  return response;
};

export const authTest = async (): Promise<void> => {
  const fn = firebase.app().functions('asia-northeast1').httpsCallable('authTest');
  await fn({});
};

// 예상합의금 전송 요청
export const transferEstimatedMoneyData = async (
  loggedId: string,
): Promise<any> => {
  try {
    const fn = firebase.app().functions('asia-northeast1').httpsCallable('transferEstimatedMoneyData');
    const response = await fn({
      loggedId: loggedId,
    });
    return response.data;
  } catch (err) {
    if (err.code === 'unavailable') {
      throw new Error(err.message);
    }
    // console.log('>>>', JSON.stringify(err), err);
  }
};
