import * as apis from '../../apis';

import {
  AccidentCategoryType,
  AccidentForm,
  EstimatedMoneyDetailInfo,
  EstimatedMoneyStatus,
  MedicalCenter,
  WoundedDetail,
} from '../../types';
import { Alert, Text } from 'react-native';
import React, { useEffect, useState } from 'react';

import AcciHospitalType from '../shared/SettlementMoneyParts/AcciHospitalType';
import AcciTreatmentPeriod from '../shared/SettlementMoneyParts/AcciTreatmentPeriod';
import AcciTreatmentSituation from '../shared/SettlementMoneyParts/AcciTreatmentSituation';
import AcciWoundedBodyNew from '../shared/SettlementMoneyParts/AcciWoundedBodyNew';
import AcciWoundedPart from '../shared/SettlementMoneyParts/AcciWoundedPart';
import AccidentCategoryCards from '../shared/SettlementMoneyParts/AccidentCategory';
import AccidentDate from '../shared/SettlementMoneyParts/AccidentDate';
import AccidentFault from '../shared/SettlementMoneyParts/AccidentFault';
import AccidentFormSelect from '../shared/SettlementMoneyParts/AccidentFormSelect';
import Button from '../shared/Button';
import CalculatingStatus from '../shared/CalculatingStatus';
import DesktopWrapper from '../shared/DesktopWrapper';
import HomeLink from '../shared/HomeLink';
import Select from 'react-select';
import SignInOut from '../shared/SignInOut';
import { colors } from '../../theme';
import { isBrowser } from 'react-device-detect';
import ko from 'date-fns/locale/ko';
import moment from 'moment';
import { registerLocale } from 'react-datepicker';
import styled from 'styled-components/native';
import { useAppContext } from '../../providers/AppProvider';
import { useGlobalLoadingContext } from '../../providers/GlobalLoadingProvider';
import { useHistory } from 'react-router-dom';
import { useSagocareContext } from '../../providers/SagocareProvider';

registerLocale('ko', ko);

const Container = styled.View`
  flex: 1;
  align-items: center;
  justify-content: center;
  border-width: 0;
  border-color: red;
  overflow: 'hidden';
`;

const MobileWrapper = styled.View`
  flex-grow: ${isBrowser ? 0 : 1};
  flex-shrink: ${isBrowser ? 0 : 1};
  flex-basis: ${isBrowser ? 'auto' : 0};
  width: ${isBrowser ? 360 : window.innerWidth};
  height: ${isBrowser ? 720 : window.innerHeight};
  overflow: hidden;
  border-width: 0;
  border-color: red;
`;

const MobileScrollView = styled.ScrollView`
  background-color: ${colors.white};
  width: 100%;
  height: 100%;
`;

const Wrapper = styled.View`
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  border-width: 0;
  border-color: red;
  padding-left: 20;
  padding-right: 20;
`;

export const AccentTitle = styled.Text`
  font-family: Noto Sans KR;
  font-weight: bold;
  font-size: 20;
  line-height: 26;
  color: ${({ theme }): string => theme.accentTitle};
`;

export const Title = styled.Text`
  font-family: Noto Sans KR;
  font-weight: bold;
  font-size: 14;
  color: ${colors.black700};
  margin-bottom: 10px;
`;

const SelectCardWrapper = styled.View`
  width: 100%;
  border-style: dashed;
  border-color: ${colors.black200};
  border-radius: 12;
  border-width: 1;
  height: 60;
  align-items: center;
  justify-content: center;
`;

const SelectCardText = styled.Text`
  font-weight: 400;
  font-family: Noto Sans KR;
  font-size: 14;
  color: ${colors.black400};
`;

const StyledSplitBar = styled.View`
  align-self: center;
  width: 100%;
`;

const StyledText = styled.Text`
  font-family: Noto Sans KR;
  font-size: 13;
`;

// Modal.setAppElement('#root');

interface Props {
  testID?: string;
  // navigation: RootStackNavigationProps<'Temp'>;
  // navigation: RootStackNavigationProps<'MainStack'>;
  // route: RouteProp<MainStackParamList, 'Temp'>;
}

// Modal.setAppElement('#root');

function SettlementMoneyMobile(): React.ReactElement {
  const history = useHistory();
  const {
    state: { globalLoading },
    setGlobalLoading,
  } = useGlobalLoadingContext();
  const [
    selectedAccidentCategoryType, setSelectedAccidentCategoryType,
  ] = useState<AccidentCategoryType>();
  const [selectedAccidentForm, setSelectedAccidentForm] = useState<AccidentForm>();
  const [statusbarTrigger, setStatusbarTrigger] = useState(false); // 상태바 열고 닫기 트리거
  const [estimatedMoneyStatus, setEstimatedMoneyStatus] = useState<
    EstimatedMoneyStatus
  >(); // 합의금 계산 상태 정보
  const [statusbarLoading, setStatusbarLoading] = useState<boolean>(false);
  // const [loading, setLoading] = useState<boolean>(false);
  // const { toastHide, toastShow } = useToastContext();
  const {
    state: { settlementMoneyInputData, estimatedMoney },
    updateSettlementMoneyInputData,
    updateEstimatedMoney,
    updateEstimatedMoneyDetailInfo,
    addWoundedDetail,
    removeWoundedDetail,
  } = useSagocareContext();

  const {
    state: {
      baseAccidentCategories,
      baseAccidentForms,
      baseWoundedParts,
      user,
    },
  } = useAppContext();
  // const navigation = useNavigation();

  const [scrollEnabled, setScrollEnabled] = React.useState(true);

  const selectRef = React.useRef<Select>();
  // 사고유형 선택 시
  const onSelectAccidentCategoryType = (
    selAccCategoryType: AccidentCategoryType,
  ): void => {
    setSelectedAccidentCategoryType(selAccCategoryType);
    updateSettlementMoneyInputData({
      ...settlementMoneyInputData,
      accidentCategory: baseAccidentCategories[selAccCategoryType],
      accidentForm: undefined,
      // accidentCategoryType: selAccCategoryType,
    });
    selectRef.current && selectRef.current.focus();
  };
  // 사고유형 상세 선택 시
  const onSelectAccidentForm = (selAccForm: AccidentForm): void => {
    setSelectedAccidentForm(selAccForm);
    updateSettlementMoneyInputData({
      ...settlementMoneyInputData,
      accidentForm: selAccForm,
      selectedFaultRatio: selAccForm.faultRatio,
    });
  };
  // 과실 값 변경 시
  const onFaultValueChanged = (value: number | number[]): void => {
    console.log('>>> value onFaultValueChanged = ', value);
    updateSettlementMoneyInputData({
      ...settlementMoneyInputData,
      selectedFaultRatio: value,
    });
  };
  // const onFaultValueSlidingStart = (): void => {
  //   setScrollEnabled(false);
  // };
  // const onFaultValueSlidingComplete = (): void => {
  //   setScrollEnabled(true);
  // };
  // 부상부위 상세 선택 시
  const onSelectWoundedDetail = (woundedDetail: WoundedDetail): void => {
    if (settlementMoneyInputData.woundedDetails.length >= 5) {
      // toastShow({
      //   text: '부상부위는 최대 5개만 선택 가능합니다.',
      //   duration: 1500,
      //   position: screenHeight - 110,
      // });
    } else {
      const wound: WoundedDetail = {
        ...woundedDetail,
        woundedPart: baseWoundedParts[woundedDetail.woundedPartType],
      };
      addWoundedDetail(wound);
    }
  };
  // 부상부위 상세 삭제 시
  const onDeleteWoundedDetail = (woundedDetail: WoundedDetail): void => {
    removeWoundedDetail(woundedDetail);
  };
  // 입원일수 변경 시
  const onStayHospitalDays = (days): void => {
    updateSettlementMoneyInputData({
      ...settlementMoneyInputData,
      stayHospitalDays: days,
    });
  };
  const onGoingHospitalDays = (days): void => {
    updateSettlementMoneyInputData({
      ...settlementMoneyInputData,
      goingHospitalDays: days,
    });
  };
  // 의료기관 변경 시
  const onSelectMedicalCenter = (medicalCenter: MedicalCenter): void => {
    if (!medicalCenter) return;
    updateSettlementMoneyInputData({
      ...settlementMoneyInputData,
      medicalCenterType: medicalCenter.id,
      // medicalCenter: medicalCenter,
    });
  };
  // 향후 치료기간 변경 시
  const onChangeFutureTreatmentPeriod = (days: number): void => {
    updateSettlementMoneyInputData({
      ...settlementMoneyInputData,
      futureTreatmentPeriod: days,
    });
  };

  const diffTime = (): number => {
    return Math.ceil(
      (new Date().getTime() -
        (settlementMoneyInputData.accidentDate
          ? new Date(settlementMoneyInputData.accidentDate).getTime()
          : 0)) /
        86400000,
    );
  };

  // 필수 입력 여부 확인
  const confirmed = (): boolean => {
    if (!settlementMoneyInputData.accidentDate) {
      alert('사고일자를 입력하세요');
      return false;
    }
    if (
      moment(settlementMoneyInputData.accidentDate).format('YYYYMMDD') >
      moment().format('YYYYMMDD')
    ) {
      alert('사고일자는 오늘 이후가 될 수 없습니다.');
      return false;
    }
    if (!settlementMoneyInputData.accidentCategory) {
      alert('사고유형을 입력하세요');
      return false;
    }
    if (!settlementMoneyInputData.accidentForm) {
      alert('사고형태를 입력하세요');
      return false;
    }
    if (settlementMoneyInputData.selectedFaultRatio === undefined) {
      alert('과실을 입력하세요');
      return false;
    }
    if (
      !settlementMoneyInputData.woundedDetails ||
      settlementMoneyInputData.woundedDetails.length === 0
    ) {
      alert('부상부위를 입력하세요');
      return false;
    }
    // if (!settlementMoneyInputData.stayHospitalDays) {
    //   Alert.alert('입원일수를 입력하세요');
    //   return false;
    // }
    // if (!settlementMoneyInputData.goingHospitalDays) {
    //   Alert.alert('통원일수를 입력하세요');
    //   return false;
    // }
    if (
      settlementMoneyInputData.futureTreatmentPeriod === undefined ||
      settlementMoneyInputData.futureTreatmentPeriod === 0
    ) {
      alert('향후 치료기간을 입력하세요');
      return false;
    }
    if (
      (settlementMoneyInputData.stayHospitalDays || 0) > diffTime() ||
      (settlementMoneyInputData.goingHospitalDays || 0) > diffTime()
    ) {
      alert(
        '입원일수와 통원일수는 사고일로부터 오늘까지의 기간을 초과 할 수 없습니다.',
      );
      return false;
    }
    return true;
  };
  // 확인 버튼 클릭 시
  const onConfirm = async (): Promise<void> => {
    // setLoading(true);
    setGlobalLoading(true);
    try {
      if (confirmed()) {
        const resData = await apis.requestEstimatedMoney(
          settlementMoneyInputData,
        );
        const { estimatedMoney, estimatedMoneyDetailInfo, loggedId } = resData as {
          estimatedMoney: number;
          estimatedMoneyDetailInfo: EstimatedMoneyDetailInfo;
          loggedId: string
        };
        // 예상합의금 계산 상세정보 저장
        if (user.uid) {
          // 로그인 상태라면, 예상합의금 금액도 저장한다.
          updateEstimatedMoney(estimatedMoney);
          updateEstimatedMoneyDetailInfo(estimatedMoneyDetailInfo);
        }
        const location: Record<string, any> = {
          pathname: '/result',
          state: {
            estimatedMoney,
            estimatedMoneyDetailInfo,
            loggedId,
          },
        };
        history.push(location);
      } else {
        console.log('>> 펄스');
      }
    } catch (err) {
      if (err.code === 'already-exists') {
        // 계산 결과, 이미 데이터 존재하면.. (이 단계에서는 있을 수 없는 일)
        if (__DEV__) {
          Alert.alert('합의금계산을 이미 했습니다. 앱을 재 실행 해주세요');
          // navigation.navigate('SettlementMoneyAfterCalculate', { estimatedMoney: estimatedMoney });
        } else {
          Alert.alert('Fatal error..');
        }
      }
      console.warn(err);
    } finally {
      setGlobalLoading(false);
      // setLoading(false);
    }
  };

  // settlementMoneyInputData 값이 변경되면 상태정보 요청을 보낸다.
  const requestEstimatedMoneyStatus = async (): Promise<void> => {
    // 필수정보 입력된 상태이면, 자동으로 상태 열기 트리거를 날린다.
    // 계산 필요 입력값 체크
    const {
      woundedDetails,
      stayHospitalDays,
      goingHospitalDays,
      medicalCenterType,
      futureTreatmentPeriod,
      selectedFaultRatio,
    } = settlementMoneyInputData;

    if (
      woundedDetails.length === 0 ||
      stayHospitalDays === undefined ||
      stayHospitalDays < 0 ||
      goingHospitalDays === undefined ||
      goingHospitalDays < 0 ||
      !medicalCenterType ||
      !futureTreatmentPeriod ||
      selectedFaultRatio === undefined
    ) {
      console.log('>> 필수 입력 오류');
    } else {
      setStatusbarTrigger(!statusbarTrigger); // statusbar 열기 트리거 날림
    }

    setStatusbarLoading(true);
    const estimatedMoneyStatus = await apis.requestEstimatedMoneyStatus(
      settlementMoneyInputData,
    );
    console.log('>> estimatedMoneyStatus = ', estimatedMoneyStatus);
    setEstimatedMoneyStatus(estimatedMoneyStatus);
    setStatusbarLoading(false);
  };
  useEffect(() => {
    requestEstimatedMoneyStatus();
  }, [settlementMoneyInputData]); // settlementMoneyInputData 값이 변경되면 상태정보 요청을 보낸다.

  return (
    <MobileWrapper nativeID="settlementmoneywrapper">
      <CalculatingStatus
        estimatedMoneyStatus={estimatedMoneyStatus}
        trigger={statusbarTrigger}
        statusbarLoading={statusbarLoading}
      />
      <MobileScrollView
        onScroll={(event):void => {
          const { nativeEvent: { contentOffset: { x, y } } } = event;
          console.log(y);
        }}
        showsVerticalScrollIndicator={false}
        nestedScrollEnabled={false}
        scrollEnabled={scrollEnabled}
      >
        {/* <div>{isBrowser ? 'browser' : 'mobile'}</div> */}
        <Wrapper>
          <StyledSplitBar style={{ marginTop: 20 }} />
          <AccentTitle style={{ color: 'black', fontWeight: 'bold' }}>
            {'사고 정보'}
          </AccentTitle>
        </Wrapper>
        {/* 사고 일자 */}
        <Wrapper style={{ paddingVertical: 20, zIndex: 9999 }}>
          <Title>{'사고 일자'}</Title>
          <AccidentDate
            onSelect={(date): void => {
              console.log('>>> 사고일자 : ', date.toString());
              updateSettlementMoneyInputData({
                ...settlementMoneyInputData,
                accidentDate: date.toJSON(),
              });
            }}
            accidentDate={settlementMoneyInputData.accidentDate}
          />
        </Wrapper>
        <StyledSplitBar style={{ height: 1, backgroundColor: '#efefef' }} />
        {/* 사고 유형 */}
        <Wrapper style={{ paddingVertical: 20 }}>
          <Title>{'사고 유형'}</Title>
          <AccidentCategoryCards
            onSelectAccidentCategoryType={onSelectAccidentCategoryType}
            selectedAccidentCategoryType={selectedAccidentCategoryType}
          />
        </Wrapper>
        {/* 사고 형태 */}
        <Wrapper style={{ paddingBottom: 20 }}>
          <Title>{'사고 형태'}</Title>
          <AccidentFormSelect
            accidentCategoryType={selectedAccidentCategoryType}
            onSelectAccidentForm={onSelectAccidentForm}
            selectRef={selectRef}
          />
        </Wrapper>
        <StyledSplitBar style={{ backgroundColor: '#efefef', height: 1 }}
        />
        {/* 과실 */}
        <Wrapper style={{ paddingTop: 20 }}>
          <Title>{'과실'}</Title>
          <AccidentFault
            selectedAccidentForm={selectedAccidentForm}
            onValueChange={onFaultValueChanged}
          />
        </Wrapper>
        <StyledSplitBar
          style={{
            backgroundColor: '#efefef',
            marginTop: 34,
            marginBottom: 34,
            height: 5,
          }}
        />
        {/* 피해 및 치료 정보 */}
        {/* 부상 부위 */}
        <Wrapper style={{ marginBottom: 10 }}>
          <AccentTitle style={{ color: 'black' }}>
            {'피해 및 치료 정보'}
          </AccentTitle>
          <Title style={{ marginTop: 10 }}>{'부상 부위'}</Title>
        </Wrapper>
        {!settlementMoneyInputData.woundedDetails ||
        settlementMoneyInputData.woundedDetails.length === 0 ? (
            <Wrapper>
              <SelectCardWrapper>
                <SelectCardText>
                  {'부상부위를 선택하세요. (최대 5개)'}
                </SelectCardText>
              </SelectCardWrapper>
            </Wrapper>
          ) : null}
        {settlementMoneyInputData.woundedDetails.map((woundedDetail, index) => {
          return (
            <Wrapper key={index}>
              <AcciWoundedPart
                woundedDetail={woundedDetail}
                onDelete={onDeleteWoundedDetail}
                hide={false}
              />
            </Wrapper>
          );
        })}
        <Wrapper>
          <AcciWoundedBodyNew
            selectedWoundedDetails={settlementMoneyInputData.woundedDetails}
            onSelectWoundedDetail={(woundedDetail: WoundedDetail): void => {
              onSelectWoundedDetail(woundedDetail);
            }}
            slidingOn={(): void => setScrollEnabled(false)}
            slidingOff={(): void => setScrollEnabled(true)}
          />
        </Wrapper>
        <StyledSplitBar
          style={{
            backgroundColor: '#efefef',
            marginTop: 20,
            marginBottom: 20,
            height: 1,
          }}
        />

        {/* 치료 상황 */}
        <Wrapper>
          <Title>{'치료 상황'}</Title>
          <AcciTreatmentSituation
            stayHospitalDays={settlementMoneyInputData.stayHospitalDays}
            goingHospitalDays={settlementMoneyInputData.goingHospitalDays}
            onStayHospitalDays={onStayHospitalDays}
            onGoingHospitalDays={onGoingHospitalDays}
          />
        </Wrapper>
        {/* 의료기관 종류 / 향후 치료 기간 */}
        <StyledSplitBar
          style={{
            backgroundColor: colors.softBlue,
            marginTop: 20,
            marginBottom: 15,
            height: 5,
          }}
        />
        <Wrapper>
          <Title style={{ marginBottom: 10 }}>{'향후 치료 계획'}</Title>
          <StyledText style={{ color: colors.softBlue, marginBottom: 10 }}>
            {'합의금 산정 시 가장 중요한 영역으로, 앞으로 치료받을 기간과 ' +
              '방문할 의료기관의 종류를 알려주세요.'}
          </StyledText>
        </Wrapper>
        <Wrapper>
          <AcciTreatmentPeriod
            futureTreatmentPeriod={
              settlementMoneyInputData.futureTreatmentPeriod
            }
            onChange={onChangeFutureTreatmentPeriod}
          />
        </Wrapper>
        <StyledSplitBar style={{ marginTop: 20 }} />
        <Wrapper style={{ marginTop: 10 }}>
          <AcciHospitalType
            medicalCenterType={settlementMoneyInputData.medicalCenterType}
            onSelectMedicalCenter={onSelectMedicalCenter}
          />
        </Wrapper>
        <StyledSplitBar
          style={{
            marginTop: 34,
            height: 5,
            backgroundColor: colors.black100,
          }}
        />

        {/* 주의사항 */}
        <Wrapper>
          <Text
            style={{
              fontFamily: 'Noto Sans KR',
              fontSize: 13,
              lineHeight: 18,
              color: colors.black400,
              paddingVertical: 20,
            }}
          >
            {'사고링크는 변호사법 및 보험업법을 준수하기 위해 최선을 ' +
              '다해 노력하고 있습니다. 사고링크의 합의금 계산 서비스는 ' +
              '고객이 작성한 내용을 토대로 자동차 보험 약관에 따라 산출하고 ' +
              '통계에 따른 정보를 제공합니다. 각 개별적인 권리분석이 ' +
              '아닌 일반적인 수준의 정보를 제공합니다. ' +
              '사고링크에서는 고객과 보험사 간 협상에 전혀 개입하지 않으며, ' +
              '이와 관련된 모든 내용에 대해 어떠한 법적 책임이 없음을 고지합니다..'}
          </Text>
        </Wrapper>
      </MobileScrollView>
      <Button
        isLoading={globalLoading}
        activeOpacity={1}
        onClick={(): void => {
          onConfirm();
        }}
        style={{
          width: '100%',
          height: 56,
          backgroundColor: colors.exitBlue,
          borderColor: colors.exitBlue,
          alignItems: 'center',
          justifyContent: 'center',
          overflow: 'hidden',
        }}
        text={'확인'}
        textStyle={{
          fontWeight: '700',
          fontFamily: 'Noto Sans KR',
          color: colors.white,
          fontSize: 18,
        }}
      />
      <div id="modal-root"/>
    </MobileWrapper>
  );
}

const SettlementMoney = (): React.ReactElement => {
  return (
    <Container>
      {isBrowser ? (
        <DesktopWrapper>
          <SettlementMoneyMobile />
        </DesktopWrapper>
      ) : (
        <SettlementMoneyMobile />
      )}
      {isBrowser ? <HomeLink /> : null}
      {isBrowser ? <SignInOut /> : null}
    </Container>
  );
};

export default SettlementMoney;

// const SettlementMoneyHeader = (props: Props): React.ReactElement => {
//   return (
//     <>
//       <BackButtonHeader
//         title={'예상 합의금 계산하기'}
//         onClick={(): void => props.navigation.goBack()}
//       />
//     </>
//   );
// };
// export const SettlementMoneyHeaderOptions = (): { header: any } => ({
//   header: SettlementMoneyHeader,
// });
