import {
  AccidentCategory,
  AccidentCategoryType,
  AccidentForm,
  AdmDistrict,
  MedicalCenter,
  MedicalCenterType,
  WoundedDetail,
  WoundedPart,
} from '../types';

import firebase from 'firebase';
// import { webConfig } from '../../config';

// if (!firebase.apps.length) {
//   // Initialize Firebase
//   firebase.initializeApp(webConfig);
//   // firebase.analytics();
// }

function sleep(ms = 0): Promise<void> {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

// 기본 데이터 :: 사고 유형
export const fetchBaseAccidentCategory = async (): Promise<{[key: string]: AccidentCategory}> => {
  const data: {[key: string]: AccidentCategory} = {};
  const snap = await firebase.app().firestore().collection('baseAccidentCategories').get();
  snap.docs.forEach((doc) => {
    data[doc.id] = doc.data() as AccidentCategory;
  });
  return data;
};

// 기본 데이터 :: 사고 세부유형 (사고형태)
export const fetchBaseAccidentForms = async (): Promise<{[key: string]: AccidentForm}> => {
  const data: {[key: string]: AccidentForm} = {};
  const snap = await firebase.app().firestore().collection('baseAccidentForms').get();
  snap.docs.forEach((doc) => {
    data[doc.id] = doc.data() as AccidentForm;
  });
  return data;
};

// 기본 데이터 :: 부상 부위
export const fetchBaseWoundedParts = async (): Promise<{[key: string]: WoundedPart}> => {
  const data: {[key: string]: WoundedPart} = {};
  const snap = await firebase.app().firestore().collection('baseWoundedParts').get();
  snap.docs.forEach((doc) => {
    data[doc.id] = doc.data() as WoundedPart;
  });
  return data;
};
// 기본 데이터 :: 상세 부상부위
export const fetchBaseWoundedDetails = async (): Promise<WoundedDetail[]> => {
  const snap = await firebase.app().firestore().collection('baseWoundedDetails').get();
  const arr = snap.docs.map((doc) => {
    const data = doc.data() as WoundedDetail;
    return data;
  });
  return arr;
};
// 기본 데이터 :: 의료기관 데이터
export const fetchBaseMedicalCenters = async (): Promise<{[key: string]: MedicalCenter}> => {
  const data: {[key: string]: MedicalCenter} = {};
  const snap = await firebase.app().firestore().collection('baseMedicalCenters').get();
  snap.docs.forEach((doc) => {
    data[doc.id] = doc.data() as MedicalCenter;
  });
  return data;
};
