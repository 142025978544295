import {
  Add14days,
  Add21days,
  Add28days,
  Add7days,
  Infoicon,
} from '../../../utils/Icons';
import {
  TextInput as EditText,
  Image,
  TouchableOpacity,
  View,
} from 'react-native';

import InfoModal from './InfoModal';
import React from 'react';
import { colors } from '../../../theme';
import styled from 'styled-components/native';

const RowWrapper = styled.View`
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  border-width: 0;
  border-color: red;
`;

const Title = styled.Text`
  font-family: Noto Sans KR;
  font-size: 14;
  color: ${colors.black700};
`;

interface Props {
  futureTreatmentPeriod?: number;
  onChange: (days: number) => void;
}

const AcciTreatmentPeriod = (props: Props): React.ReactElement => {
  // const { theme } = useThemeContext();
  const addValue = (val: number): void => {
    props.onChange((props.futureTreatmentPeriod || 0) + val);
  };
  const [modalOnOff, setModalOnOff] = React.useState(false);
  const modalOn = (): void => {
    setModalOnOff(true);
  };
  const modalOff = (): void => {
    setModalOnOff(false);
  };
  return (
    <>
      <RowWrapper style={{ marginTop: 10 }}>
        <Title style={{ fontWeight: 'bold' }}>{'향후 치료 기간(일)'}</Title>
        <EditText
          testID="input-insname"
          // errorTestID="error-insname"
          // textStyle={{
          //   // fontFamily: 'NotoSansCJKkr-Regular',
          //   fontWeight: 'normal',
          //   fontSize: props.futureTreatmentPeriod !== undefined ? 14 : 12,
          //   lineHeight: 20,
          //   color: theme.fontColor,
          //   paddingBottom: 5,
          // }}
          // label={''}
          placeholder={'향후 치료 기간을 입력하세요.'}
          // borderColor={theme.font}
          // focusColor={colors.exitBlue}
          placeholderTextColor={colors.black200}
          value={props.futureTreatmentPeriod?.toString() || ''}
          onChangeText={(text): void => {
            props.onChange(Number(text));
          }}
          style={{
            fontFamily: 'Noto Sans KR',
            fontSize: 12,
            width: 200,
            borderWidth: 0,
            borderColor: 'blue',
            backgroundColor: colors.white,
            textAlign: 'right',
            paddingRight: 3,
          }}
          onFocus={(): void => {
            // setScrollPadding(-180);
          }}
          onBlur={(): void => {
            // setScrollPadding(0);
          }}
          // type={EditTextInputType.ROW}
          keyboardType="numeric"
        />
      </RowWrapper>
      <RowWrapper style={{ marginTop: 10 }}>
        <TouchableOpacity
          onPress={modalOn}
          style={{
            width: 20,
            height: 20,
            justifyContent: 'center',
            alignItems: 'center',
            right: 0,
            borderWidth: 0,
            borderColor: 'red',
          }}
        >
          <Image source={Infoicon} style={{ width: 16, height: 16 }} />
        </TouchableOpacity>
        {/* <InfoModal
          modalOnOff={modalOnOff}
          modalOff={modalOff}
        /> */}
        <View
          style={{
            width: 200,
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'flex-end',
          }}
        >
          <TouchableOpacity
            onPress={(): void => {
              addValue(7);
              // const newValue = Number(value);
              // const addedValue = newValue + 7;
              // setValue(String(addedValue));
            }}
          >
            <Image source={Add7days} style={{ width: 43, height: 24 }} />
          </TouchableOpacity>
          <TouchableOpacity
            onPress={(): void => {
              addValue(14);
            }}
          >
            <Image source={Add14days} style={{ width: 43, height: 24 }} />
          </TouchableOpacity>
          <TouchableOpacity
            onPress={(): void => {
              addValue(21);
            }}
          >
            <Image source={Add21days} style={{ width: 43, height: 24 }} />
          </TouchableOpacity>
          <TouchableOpacity
            onPress={(): void => {
              addValue(28);
            }}
          >
            <Image source={Add28days} style={{ width: 43, height: 24 }} />
          </TouchableOpacity>
        </View>
      </RowWrapper>
      <InfoModal
        modalOnOff={modalOnOff}
        modalOff={modalOff}
      />
    </>
  );
};

export default AcciTreatmentPeriod;
