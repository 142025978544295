import '@react-native-firebase/firestore';

import { SFC, TimeHTMLAttributes } from 'react';
import { StyleProp, TextStyle } from 'react-native';

import { AbortController } from 'abort-controller';

export enum PlatformType {
  WEB = 'web',
  APP = 'app',
}
// 케어팁(알림) 데이터
export enum CaretipType {
  INFOMATION = 'info', // 알림
  TREATMENT = 'treatment', // 치료
  AGREEMENT = 'agreement', // 합의
}
export enum HospitalBookingCheckType {
  necessary = 'necessary', // 진료예약 필수 조건
  none = 'none', // 진료예약 없음 조건
  defaule = '', // 무관
}
// 케어팁 알림 발송 조건
export interface NotiCondition {
  immediacy?: boolean; // 케어서비스 등록 즉시 여부
  hospitalBookingCheck: HospitalBookingCheckType; // 병원 예약 조건 확인
  acciDateElapsed: number; // 사고일자 경과 조건
  careDateElapsed: number; // 사고케어 등록일 경과 조건
}
// 알림으로 수신한 케어팁 데이터
export interface ReceivedCaretipData {
  uid: string;
  receivedDate: string; // ** JSON
  caretipData: CaretipData;
  hasRead: boolean; // 읽음 여부
}
// 케어서비스 시작 시 미리 정해놓은 케어팁 데이터
export interface PresetCaretipData {
  occurDate: string; // 케어팁 발생하는 날짜 (Date string)
  caretipData: CaretipData;
  hasRead: boolean; // 읽음 여부
}
// 케어팁 데이터
export interface CaretipData {
  caretipId: string; // 케어팁 데이터 아이디
  type: CaretipType; // 케어팁 데이터 타입
  caretipHtmlFilename: string; // 케어팁 html 파일명
  uri?: string; // 파일 uri (fetch할 때 추가됨)
  notiTitle?: string; // 케어팁 알림 : 제목
  notiSubtitle?: string; // 케어팁 알림 : 부제목
  notiBody: string; // 케어팁 알림 : 내용
  notiCondition: NotiCondition; // 케어팁 알림 발송 조건
  notiTime?: number; // 알림 보내는 시각 (HH)
  notiActionScreen: string; // 알림 눌러서 이동할 화면 (대부분 'Notice')
  enabled: boolean; // 데이터를 사용여부
}

export interface SagolinkNotification {
  notiIdx?: string;
  title?: string;
  titleIOS?: string;
  subtitle?: string;
  body: string;
  bodyIOS?: string;
  android: {
    channelId: string;
    channelName: string;
    color?: string;
    pressAction: {
      launchActivity: string;
      id: string;
    };
  };
  data: {
    screen: string;
    caretipId: string;
  };
}

// 손사 연결 신청 폼
export interface RequestConnectAdjusterForm {
  adjuster?: Adjuster;
  treatmentType?: TreatmentType;
  requestName: string;
  requestPhone: string;
  insCompany?: string;
  insNumber?: string;
  requested?: boolean; // 요청 완료 여부
  adjusterAccept?: boolean; // 수임완료 여부
}
export const initialRequestConnectAdjusterForm: RequestConnectAdjusterForm = {
  requestName: '',
  requestPhone: '',
  requested: false,
  adjusterAccept: false,
};
// 치료 진행 종류 enum
export enum TreatmentType {
  StayHospital = 'stayhospital', // 입원 치료중
  OngoingHospital = 'ongoinghospital', // 통원 치료중
  NotYetTreatment = 'notyettreatment', // 치료받기 전
}
// 의료기관 타입 enum
export enum MedicalCenterType {
  orientalClinic = 'orientalClinic', // 한의원
  surgeryClinic = 'surgeryClinic', // 정형외과
}

export interface MedicalCenter {
  id: MedicalCenterType; // 아이디 (MedicalCenterType)
  value: string; // 의료기관 값
  label: string; // 의료기관 표시 레이블
  createdAt: firebase.firestore.Timestamp;
}

// 유저가 입력한 예상합의금 계산 정보 (local storage와 싱크함 - 앱 실행시)
export interface SettlementMoneyInputData {
  hasAutoFilled: boolean; // 챗봇 데이터 끌어다가 자동입력 최초 한번 했는지 여부
  accidentDate?: string; // 사고 일자 ** JSON
  // accidentCategoryType?: AccidentCategoryType; // 사고유형 타입
  accidentCategory?: AccidentCategory; // 사고 유형
  accidentForm?: AccidentForm; // 사고 세부 유형
  selectedFaultRatio?: number | number[]; // 기입한 과실 비율 (유저 기준)
  // woundedPartType?: WoundedPartType; // 부상부위 타입
  // woundedPart?: WoundedPart; // 부상부위
  woundedDetails: WoundedDetail[]; // 상세 부상부위
  stayHospitalDays?: number; // 입원 일수
  goingHospitalDays?: number; // 통원 일수
  // medicalCenter?: MedicalCenter; // 의료기관
  medicalCenterType: MedicalCenterType; // 의료기관 타입
  futureTreatmentPeriod?: number; // 향후 치료 기간
}
// 예상합의금 정보 초기값
export const initialSettlementMoneyInputData: SettlementMoneyInputData = {
  hasAutoFilled: false,
  woundedDetails: [],
  stayHospitalDays: 0,
  goingHospitalDays: 0,
  medicalCenterType: MedicalCenterType.orientalClinic,
  // selectedFaultRatio: 50,
};

export interface EstimatedMoneyExtraInfo {
  woundedLevel: number; // 부상급수
  stayHospitalDays: number; // 입원일수
  goingHospitalDays: number; // 통원일수
  futureTreatmentPeriod: number; // 향후치료일수
  faultRatio: number; // 과실비율
}
// 예상합의금 계산 상세 정보
export interface EstimatedMoneyDetailInfo {
  calculatedAt?: string; // 계산일시 (unixtimestamp) ** JSON
  woundedMoney?: number; // 위자료
  damagedPay?: number; // 휴업손해액
  etcDamagedMoney?: number; // 기타손배금
  futureTreatmentMoney?: number; // 향후치료비
  setoffMoney?: number; // 치료비 상계금액
  suggestedGoingHospitalNumbers?: number; // 권장 통원일수
  suggestedAgreementDate?: string; // 권장 합의일자 ** JSON
  recommendAdjuster?: boolean; // 손해사정사 연결할 중상 포함 여부
  extraInfo: EstimatedMoneyExtraInfo;
}

// 부상부위 타입 enum
export enum WoundedPartType {
  headNeck = 'headNeck', // 머리,목
  shoulderCollarbone = 'shoulderCollarbone', // 어깨,쇄골
  waistRib = 'waistRib', // 허리,갈비뼈
  armElbow = 'armElbow', // 팔,팔꿈치
  pelvis = 'pelvis', // 골반
  handWrist = 'handWrist', // 손,손목
  legKnee = 'legKnee', // 다리,무릎
  footAnkle = 'footAnkle', // 발,발목
}
// 부상 부위
export interface WoundedPart {
  id: WoundedPartType;
  value: string; // 부상부위 값
  label: string; // 표시 텍스트
  createdAt: firebase.firestore.Timestamp;
}
// 상세 부상부위
export interface WoundedDetail {
  id: string;
  woundedPartType: WoundedPartType; // 부상부위 타입
  woundedPart?: WoundedPart; // 부상부위
  value: string; // 내부 사용 값
  label: string; // 화면 표현 텍스트
  woundedLevel: number; // 부상 급수
  recommendAdjuster: boolean; // 손사로 연결할 중상 여부
}

// 사고유형 enum
export enum AccidentCategoryType {
  carcar = 'carcar',
  carperson = 'carperson',
  carbike = 'carbike',
  etc = 'etc',
}

// 사고 유형
export interface AccidentCategory {
  id: AccidentCategoryType;
  value: string;
  label: string;
  createdAt: firebase.firestore.Timestamp;
}
// 사고 세부 유형 (사고)
export interface AccidentForm {
  id: string;
  accidentCategoryType: AccidentCategoryType;
  value: string; // 내부 사용 값
  label: string; // 화면 표현 텍스트
  faultRatio: number;
  createdAt: firebase.firestore.Timestamp;
}

/**
 * 알림 데이터
 */
// export interface Notice {
//   uid: string;
//   title: string;
//   noticePayload: NoticePayload; // 알림 내용
//   createdAt?: Date; // 생성일자
//   readAt?: Date; // 확인한 일자
// }
export interface NoticePayload { // 알림 내용
  noticeType: NoticeType;
  contents: {}; // 알림 타입에 따라 내용이 달라짐..
}
export enum NoticeType {
  default = '',
  sagocare = 'sagocare',
  normal = 'normal',
}

/**
 * 회원 유저정보
 */
export interface User {
  uid: string;
  // displayName?: string; // 화면에 표시할 이름
  userName: string; // 실제 유저 이름
  email?: string;
  phoneNumber?: string;
  thumbURL?: string;
  photoURL?: string;
  created?: Date;
  updated?: Date;
  signedup?: boolean; // 회원가입 된 유저
  snsLoginType?: SnsLoginType; // sns 종류
  loggedin?: boolean; // sns 로그인된 상태
}
export const initialUser: User = {
  uid: '',
  userName: 'GUEST',
  email: '',
  phoneNumber: '',
  thumbURL: '',
  photoURL: '',
  created: undefined,
  updated: undefined,

  snsLoginType: undefined, // sns 종류
  loggedin: undefined, // sns 로그인된 상태
  signedup: false, // 회원가입 된 유저
};
// SNS 로그인 시 저장되는 유저 기본 정보 타입
export interface UserPayload {
  email?: string;
  displayName: string;
  phoneNumber?: string;
  photoURL?: string;
  photoThumnail?: string;
  customSignedUp?: boolean; // 회원가입 완료 여부
  snsLoginType?: SnsLoginType; // 회원가입시 로그인한 SNS
}

export interface BusinessHours {
  beginHour: string; // 시작시간 (HH:MM)
  finishHour: string; // 종료시간 (HH:MM)
}

export interface DayBusinessHours { // 요일별 영업시간
  Mon: BusinessHours;
  Tue: BusinessHours;
  Wed: BusinessHours;
  Thu: BusinessHours;
  Fri: BusinessHours;
  Sat: BusinessHours;
  Sun: BusinessHours;
}
export const defaultDayBusinessHours: DayBusinessHours = {
  Mon: { beginHour: '09:00', finishHour: '18:00' },
  Tue: { beginHour: '09:10', finishHour: '18:10' },
  Wed: { beginHour: '09:10', finishHour: '18:10' },
  Thu: { beginHour: '09:10', finishHour: '18:10' },
  Fri: { beginHour: '09:10', finishHour: '18:10' },
  Sat: { beginHour: '09:10', finishHour: '18:10' },
  Sun: { beginHour: '09:10', finishHour: '18:10' },
};

export interface Coord {
  _latitude: number; // 위도
  _longitude: number; // 경도
}
export const initCoord: Coord = {
  _latitude: 37.4637143777603,
  _longitude: 127.03567948032757,
};
export interface MapBounds { // 지도 영역
  sw: Coord; // 남서 좌표
  ne: Coord; // 북동 좌표
}
export interface MapInfo { // 지도 정보 (중심좌표, 지도 영역)
  center: Coord;
  bounds: MapBounds;
}
export interface AdmDistrict { // 행정구역
  sido: string; // 시도
  sigungu: string; // 시군구 ('' 이면 시도 자체)
  coord: Coord;
}
export interface Hospital {
  uid: string;
  no: number; // 엑셀 관리 번호
  // booked?: boolean; // 예약중 상태 여부
  hospitalName: string;
  writer: string; // 담당자 (작성자 / 영업자)
  staffName: string; // 병원 실무자
  staffPhone: string;
  directorName: string; // 병원 원장
  directorPhone: string;
  email: string; // 대표 메일
  position: string; // 직위
  address: string;
  sido: string; // 시도
  sigungu: string; // 시군구
  coordinates: Coord; // 경위도 좌표
  tel: string; // 전화번호
  doctorCount: number; // 의사 수

  dayBusinessHours: DayBusinessHours; // 요일별 영업시간
  // 아래 3개를 앱 화면에서 사용함
  businessHours: string[]; // 영업시간 정보 배열 (weekday)
  holidayHours: string[]; // 휴일시간 정보 배열
  lunchHour: string; // 식사시간 정보
  etcInfos: string[]; // 기타 정보 배열

  additionInfo: string; // 기타 정보
  introduction: string; // 병원 소개글
  contractor: string; // 계약자
  contractDate: string; // 계약일자
  bizLicense: boolean; // 사업자등록증 여부
  license: boolean; // 면허증 여부
  note: string; // 특이사항

  distance?: number; // 지도 중심으로 부터 계산된 거리 M 단위
}

interface IconProps {
  style?: StyleProp<TextStyle>;
  width?: number | string;
  height?: number | string;
  children?: never;
}

export enum SnsLoginType {
  default = '',
  kakao = 'KAKAO',
  apple = 'APPLE',
}

export type IconType = SFC<IconProps>;

// api 서버 요청 후 받는 결과 타입
export interface ApiResponseType {
  msg?: string; // 메시지
  code?: string; // 리턴 코드
  result: boolean; // 결과 불
}

// 병원 예약 정보
export interface HospitalBookingInfo {
  hospital: Hospital; // 예약 병원
  user: User; // 로그인 유저
  bookDate: string; // 예약 요청 날짜 ** JSON
  bookTime: string; // 예약 요청 시간 (오전 : 오후)
  bookName: string; // 예약자 이름
  bookPhone: string; // 예약자 폰번호
  insurance?: string; // 상대보험사 이름
  insNumber?: string; // 보험접수번호
}
export interface HospitalBookingData extends HospitalBookingInfo {
  hospitalBookingListId: string;
  createdAt: firebase.firestore.Timestamp;
  expiry: boolean;
}
export interface FetchResultAdjuster {
  available: boolean;
  adjusters: Adjuster[];
}
// 손해사정사
export interface Adjuster {
  uid: string; // uid
  name: string; // 손사 이름
  phone?: string; // 전화번호
  email?: string; // 이메일
  photoUri?: string; // 이미지 uri
  company?: string; // 회사명
  address?: string; // 주소
  area?: string; // 주요활동지역
  career?: string; // 경력 (9년)
  licenseAcquisition?: string; // 자격취득 (2011년 손해사정사 자격 취득)
  licenseNumber?: string; // 작격증 번호 ("123123123")
  licenseType?: string; // 자격증 종목 (3종 손해사정사(대인))
  licenseDate?: string; // 자격증 취득일 ('YYYY-MM-DD')
  greet?: string; // 인사말
  position?: string; // 직위
  feeSlight?: number; // 경상 수수료율
  feeSerious?: number; // 중상 수수료율
  _latitude?: number; // 위도 좌표
  _longitude?: number; // 경도 좌표
  _distance?: number; // 기준점으로부터의 거리 (km)
}

// export interface SideTab {
//   show: boolean;
//   calculating: boolean;
//   result: {
//     type:  | 평균초과 | 평균이하 | 적정수준 | 평균이상 | 정보부족;
//     estimatedMoney: number | undefined;
//   };
// }

// 예상합의금 상태 코드
export enum EstimatedMoneyStatusCode {
  default = '', // 미지정
  needInfo = 'needInfo', // 정보 더 필요
  fault = 'moreFault', // 과실 많아
  less = 'less', // 평균 이하
  average = 'average', // 평균 적정 수준
  more = 'more', // 평균 이상
  most = 'most', // 평균 초과
}
export interface EstimatedMoneyStatus {
  statusCode: EstimatedMoneyStatusCode;
  statusText: string;
  aboutMoney: number;
}

// 일일 메모데이터 타입
export enum DailyMemoType {
  accident = 'accident', // 사고발생일
  startCareOngoing = 'startCareOngoing', // 사고케어 시작일
  adjusterAccept = 'adjusterAccept', // 손사 수임
  suggestedAgreementDate = 'suggestedAgreementDate', // 권장 합의일
}
// 사고캘린더 일 데이터
export interface DailyData { // 일 데이터
  dateKey: string; // YYYY-MM-DD
  hospitalBookings: HospitalBookingData[];
  presetCaretipDatas: PresetCaretipData[];
  dailyMemo: DailyMemoType[]; // 메모
}
// 일자별 사고케어 캘린더 데이터 (key = YYYY-MM-DD)
export interface DailyCalData {
  [key: string] : DailyData;
}

// 사고케어 달성율 데이터
export interface SagocareGoalRate {
  goalRate: number;
  details: {
    datePoint: number;
    caretipPoint: number;
    hospitalPoint: number;
    requestAdjusterPoint: number;
    completedAdjusterPoint: number;
  }
}

// 사고캘린더 월 데이터
// export interface MonthData {
//   month: number;
//   startDate: Date; // 화면 표시 월의 시작 일자
//   dateDatas: DateData[]; // 6주 = 42개
// }
