import {
  ImageSourcePropType,
  ViewStyle,
} from 'react-native';

import { AccidentCategoryIcons } from '../../../utils/Icons';
import React from 'react';
import { colors } from '../../../theme';
import styled from 'styled-components/native';

const StyledButton = styled.TouchableOpacity`
  background-color: ${({ theme }): string => theme.background};
  /* align-self: center; */
  flex-direction: row;
  border-radius: 10;
  border-color: ${colors.black100};
  width: 49%;
  height: 95%;
  align-items: center;
  justify-content: space-between;
  /* padding-right: 5px; */
  padding-left: 10px;
`;
const StyledImage = styled.Image`
  width: 60;
  height: 60;
  margin-left: 0;
  border-width: 0;
  border-color: red;
`;
const StyledTextWrapper = styled.View`
  flex: 1;
  align-items: center;
  border-width: 0;
  border-color: red;
`;
const StyledTextTitle = styled.Text`
  font-family: Noto Sans KR;
  font-size: 14;
  line-height: 24;
  color: ${colors.black700};
  flex: 1;
  border-width: 0;
  border-color: red;
`;

interface Props {
  testID?: string;
  title?: string;
  isCounseling: boolean;
  isDisabled?: boolean;
  onClick?: () => void;
  style?: ViewStyle;
  icon?: ImageSourcePropType;
  cardsOnOff? : Array<boolean>;
  isOn?: boolean;
}

function AccidentCategoryCard(props: Props): React.ReactElement {
  return (
    <StyledButton
      testID={props.testID}
      activeOpacity={1}
      onPress={props.onClick}
      disabled={props.isDisabled}
      style={{ ...props.style, borderWidth: 1, borderColor: props.isOn ? '#004fff' : '#dddddd' }}
    >
      {props.icon ? <StyledImage source={props.icon} /> : null}
      <StyledTextWrapper>
        <StyledTextTitle
          style={{ color: props.isOn ? '#004fff' : '#dddddd' }}
        >
          {props.title}
        </StyledTextTitle>
      </StyledTextWrapper>
    </StyledButton>
  );
}

AccidentCategoryCard.defaultProps = {
  icon: AccidentCategoryIcons.car2car,
};

export default AccidentCategoryCard;
