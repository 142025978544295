import {
  AccidentCategory,
  AccidentCategoryType,
  AccidentForm,
  AdmDistrict,
  Hospital,
  MedicalCenter,
  User,
  WoundedDetail,
  WoundedPart, initialUser,
} from '../types';
import React, { useReducer } from 'react';
import { androidConfig, iosConfig } from '../../config';

import { Platform } from 'react-native';
import createCtx from '../utils/createCtx';
import { firebase } from '@react-native-firebase/auth';

interface Context {
  state: State;
  setUser: (user: User) => void;
  resetUser: () => void;
  callDefault: () => void;
  setAdmDistricts: (admDistricts: AdmDistrict[]) => void; // 행정구역 세팅하기
  setBaseAccidentCategories: (baseAccidentCategories: {[key: string]: AccidentCategory}) => void; // 기본데이터 :; 사고 유형
  setBaseAccidentForms: (baseAccidentForms: {[key: string]: AccidentForm}) => void; // 기본데이터 :: 사고 세부 유형 (사고형태)
  setBaseWoundedParts: (baseWoundedParts: {[key: string]: WoundedPart}) => void; // 기본 데이터 :: 부상 부위
  setBaseWoundedDetails: (baseWoundedDetails: WoundedDetail[]) => void; // 기본 데이터 :; 상세 부상 부위
  setBaseMedicalCenters: (baseMedicalCenters: {[key: string]: MedicalCenter}) => void; // 기본 데이터 :: 의료기관

  setBaseHospitals: (hospitals: Hospital[]) => void; // 병원 전체 데이터 로딩
  setAdminHospitals: (hospitals: Hospital[]) => void; // 병원 전체 데이터 로딩 :: 관리자 추가 데이터
}
const [useCtx, Provider] = createCtx<Context>();

export enum ActionType {
  ResetUser = 'reset-user',
  SetUser = 'set-user',
  CallDefault = 'call-default',
  SetAdmDistricts = 'set-admdistricts',
  SetBaseAccidentForms = 'set-baseaccidentforms',
  SetBaseAccidentCategories = 'set-baseaccidentcategories',
  SetBaseWoundedParts = 'set-basewoundedparts',
  SetBaseWoundedDetails = 'set-basewoundeddetails',
  SetBaseMedicalCenters = 'set-base-medical-centers',
  SetBaseHospitals = 'set-base-hospitals',
  SetAdminHospitals = 'set-admin-hospitals',
}

export interface State {
  user: User;
  // settlementInfo: SettlementInfo;
  AdmDistricts: AdmDistrict[];
  baseAccidentCategories: {[key: string]: AccidentCategory}; // 사고 유형
  baseAccidentForms: {[key: string]: AccidentForm}; // 사고 세부 유형
  baseWoundedParts: {[key: string]: WoundedPart}; // 부상 부위
  baseWoundedDetails: WoundedDetail[]; // 상세 부상 부위
  baseMedicalCenters: {[key: string]: MedicalCenter}; // 의료기관
  baseHospitals: Hospital[]; // 병원 전체 데이터
  adminHospitals: Hospital[]; // 병원 관리자 추가 데이터
}

const initialState: State = {
  user: initialUser,
  AdmDistricts: [],
  baseAccidentCategories: {},
  baseAccidentForms: {},
  baseWoundedParts: {}, // 부상 부위
  baseWoundedDetails: [], // 상세 부상 부위
  baseMedicalCenters: {},
  baseHospitals: [],
  adminHospitals: [],
};

interface SetUserAction {
  type: ActionType.SetUser;
  payload: User;
}

interface ResetUserAction {
  type: ActionType.ResetUser;
}

interface GetStateAction {
  type: ActionType.CallDefault;
}

interface SetAdmDistrictsAction {
  type: ActionType.SetAdmDistricts;
  payload: AdmDistrict[];
}

interface SetBaseAccidentCategoriesAction {
  type: ActionType.SetBaseAccidentCategories;
  payload: {[key: string]: AccidentCategory};
}
interface SetBaseAccidentFormsAction {
  type: ActionType.SetBaseAccidentForms;
  payload: {[key: string]: AccidentForm};
}

interface SetBaseWoundedPartsAction {
  type: ActionType.SetBaseWoundedParts;
  payload: {[key: string]: WoundedPart};
}
interface SetBaseWoundedDetailsAction {
  type: ActionType.SetBaseWoundedDetails;
  payload: WoundedDetail[];
}
interface SetBaseMedicalCentersAction {
  type: ActionType.SetBaseMedicalCenters;
  payload: {[key: string]: MedicalCenter};
}
interface SetBaseHospitalsAction {
  type: ActionType.SetBaseHospitals;
  payload: Hospital[];
}
interface SetAdminHospitalsAction {
  type: ActionType.SetAdminHospitals;
  payload: Hospital[];
}
type Action = SetUserAction | ResetUserAction | GetStateAction | SetAdmDistrictsAction |
SetBaseAccidentFormsAction | SetBaseAccidentCategoriesAction |
SetBaseWoundedPartsAction | SetBaseWoundedDetailsAction |
SetBaseMedicalCentersAction | SetBaseHospitalsAction | SetAdminHospitalsAction;

interface Props {
  children?: React.ReactElement;
}

type Reducer = (state: State, action: Action) => State;

const callDefault = (dispatch: React.Dispatch<GetStateAction>) => (): void => {
  dispatch({
    type: ActionType.CallDefault,
  });
};

const setUser = (dispatch: React.Dispatch<SetUserAction>) => (
  user: User,
): void => {
  // console.log('here is in setUser...');
  dispatch({
    type: ActionType.SetUser,
    payload: user,
  });
};

const resetUser = (dispatch: React.Dispatch<ResetUserAction>) => (): void => {
  dispatch({
    type: ActionType.ResetUser,
  });
};

const setAdmDistricts = (dispatch: React.Dispatch<SetAdmDistrictsAction>) => (
  admDistricts: AdmDistrict[],
): void => {
  dispatch({
    type: ActionType.SetAdmDistricts,
    payload: admDistricts,
  });
};

const setBaseAccidentCategories = (dispatch: React.Dispatch<SetBaseAccidentCategoriesAction>) => (
  baseAccidentCategories: {[key: string]: AccidentCategory},
): void => {
  dispatch({
    type: ActionType.SetBaseAccidentCategories,
    payload: baseAccidentCategories,
  });
};
const setBaseAccidentForms = (dispatch: React.Dispatch<SetBaseAccidentFormsAction>) => (
  baseAccidentForms: {[key: string]: AccidentForm},
): void => {
  dispatch({
    type: ActionType.SetBaseAccidentForms,
    payload: baseAccidentForms,
  });
};

const setBaseWoundedParts = (dispatch: React.Dispatch<SetBaseWoundedPartsAction>) => (
  baseWoundedParts: {[key: string]: WoundedPart},
): void => {
  dispatch({
    type: ActionType.SetBaseWoundedParts,
    payload: baseWoundedParts,
  });
};
const setBaseWoundedDetails = (dispatch: React.Dispatch<SetBaseWoundedDetailsAction>) => (
  baseWoundedDetails: WoundedDetail[],
): void => {
  dispatch({
    type: ActionType.SetBaseWoundedDetails,
    payload: baseWoundedDetails,
  });
};
const setBaseMedicalCenters = (dispatch: React.Dispatch<SetBaseMedicalCentersAction>) => (
  baseMedicalCenters: {[key: string]: MedicalCenter},
): void => {
  dispatch({
    type: ActionType.SetBaseMedicalCenters,
    payload: baseMedicalCenters,
  });
};
const setBaseHospitals = (dispatch: React.Dispatch<SetBaseHospitalsAction>) => (
  hospitals: Hospital[],
): void => {
  dispatch({
    type: ActionType.SetBaseHospitals,
    payload: hospitals,
  });
};
const setAdminHospitals = (dispatch: React.Dispatch<SetAdminHospitalsAction>) => (
  hospitals: Hospital[],
): void => {
  dispatch({
    type: ActionType.SetAdminHospitals,
    payload: hospitals,
  });
};
const reducer: Reducer = (state = initialState, action) => {
  // console.log('this is in reducer.. ');
  switch (action.type) {
    case 'reset-user': // 유저만 리셋
      return { ...state, user: initialUser };
    case 'set-user':
      return { ...state, user: action.payload };
    case 'set-admdistricts':
      return { ...state, AdmDistricts: action.payload };
    case ActionType.SetBaseAccidentCategories:
      return { ...state, baseAccidentCategories: action.payload };
    case ActionType.SetBaseAccidentForms:
      return { ...state, baseAccidentForms: action.payload };
    case ActionType.SetBaseWoundedParts:
      return { ...state, baseWoundedParts: action.payload };
    case ActionType.SetBaseWoundedDetails:
      return { ...state, baseWoundedDetails: action.payload };
    case ActionType.SetBaseMedicalCenters:
      return { ...state, baseMedicalCenters: action.payload };
    case ActionType.SetBaseHospitals:
      return { ...state, baseHospitals: action.payload };
    case ActionType.SetAdminHospitals:
      return { ...state, adminHospitals: action.payload };
    default:
      return state;
  }
};

function AppProvider(props: Props): React.ReactElement {
  const [state, dispatch] = useReducer<Reducer>(reducer, initialState);

  const actions = {
    setUser: setUser(dispatch),
    resetUser: resetUser(dispatch),
    callDefault: callDefault(dispatch),
    setAdmDistricts: setAdmDistricts(dispatch),
    setBaseAccidentCategories: setBaseAccidentCategories(dispatch),
    setBaseAccidentForms: setBaseAccidentForms(dispatch),
    setBaseWoundedParts: setBaseWoundedParts(dispatch),
    setBaseWoundedDetails: setBaseWoundedDetails(dispatch),
    setBaseMedicalCenters: setBaseMedicalCenters(dispatch),
    setBaseHospitals: setBaseHospitals(dispatch),
    setAdminHospitals: setAdminHospitals(dispatch),
  };

  return <Provider value={{ state, ...actions }}>{props.children}</Provider>;
}

export { useCtx as useAppContext, AppProvider };
