import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
} from 'react-device-detect';
import { Offline, Online } from 'react-detect-offline';

import Clock from 'react-live-clock';
import React from 'react';
import WifiIcon from 'react-native-vector-icons/MaterialCommunityIcons';
import { colors } from '../../theme';
import styled from 'styled-components/native';

const SideButton = styled.TouchableOpacity`
  position: absolute;
  top: 90;
  bottom: 0;
  left: -5;
  width: 5;
  height: 30;
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
  background-color: ${colors.black700};
  z-index: 99;
`;

const MobilePackage = styled.View`
  /* overflow-x: hidden;
  overflow-y: auto; */
  /* position: -webkit-sticky; 
  position: sticky;
  top: 0; */
`;

const MobileContainer = styled.View`
  justify-content: center;
  align-items: center;
  border-width: 12px;
  border-color: ${colors.black700};
  border-radius: 40px;
  overflow: hidden;
`;

const NotchWrapper = styled.View`
  background-color: ${colors.white};
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  height: 35px;
`;

const ClockWrapper = styled.View`
  width: 25%;
  height: 30px;
  justify-content: center;
  align-items: center;
`;

const Notch = styled.View`
  width: 50%;
  height: 30px;
  background-color: ${colors.black700};
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  align-items: center;
  justify-content: center;
  /* z-index: 99; */
`;

const NotchText = styled.Text`
  font-family: Noto Sans KR;
  font-weight: bold;
  font-size: 14;
  color: ${colors.black600};
`;

const WifiWrapper = styled.View`
  width: 25%;
  height: 30px;
  justify-content: center;
  align-items: center;
`;

interface Props {
  children: React.ReactElement;
}

const DesktopWrapper = (props:Props): React.ReactElement => {
  const offsetRef = React.useRef();
  return (
    <>
      <MobilePackage>
        <SideButton />
        <SideButton
          style={{ top: 150, height: 45 }}
        />
        <SideButton
          style={{ top: 200, height: 45 }}
        />
        <SideButton
          style={{
            top: 164,
            left: 384,
            height: 64,
            borderTopRightRadius: 5,
            borderBottomRightRadius: 5,
          }}
        />
        <MobileContainer>
          <NotchWrapper>
            <ClockWrapper>
              <Clock
                format={'HH:mm'}
                timezone={'Asia/Seoul'}
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                style={{
                  fontSize: 16,
                  fontWeight: '700',
                }}
              />
            </ClockWrapper>
            <Notch><NotchText>{'••••'}</NotchText></Notch>
            <WifiWrapper>
              <Online>
                <WifiIcon name="wifi" size={15} />
              </Online>
              <Offline>
                <WifiIcon name="wifi-off" size={15} />
              </Offline>
            </WifiWrapper>
          </NotchWrapper>
          {props.children}
        </MobileContainer>
      </MobilePackage>
    </>
  );
};

export default DesktopWrapper;
