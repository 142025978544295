/** firebase configs */
export const webConfig = {
  apiKey: 'AIzaSyD25EcNd0GpnmTjvW_HXMsYwChnUPMXhDw',
  authDomain: 'sagolinkapp.firebaseapp.com',
  databaseURL: 'https://sagolinkapp.firebaseio.com',
  projectId: 'sagolinkapp',
  storageBucket: 'sagolinkapp.appspot.com',
  messagingSenderId: '279406606917',
  appId: '1:279406606917:web:6dfc68a475ca252dd9412c',
  measurementId: 'G-6VCSZ1Y1QH',
};

export const iosConfig = {
  clientId: '279406606917-26t75471ug71vmndmg365k878b5rt6mt.apps.googleusercontent.com',
  appId: 'com.sagolinkapp',
  apiKey: 'AIzaSyDMwTfoB9Nmr7Cb_u90dGffkXCNQAPXB1A',
  databaseURL: '',
  storageBucket: '',
  messagingSenderId: '279406606917',
  projectId: 'sagolinkapp',

  // enable persistence by adding the below flag
  persistence: true,
};

// export const iosConfig = {
//   clientId: '279406606917-26t75471ug71vmndmg365k878b5rt6mt.apps.googleusercontent.com',
//   appId: '1:279406606917:ios:d0fa61feca7a97cfd9412c',
//   apiKey: 'AIzaSyDMwTfoB9Nmr7Cb_u90dGffkXCNQAPXB1A',
//   databaseURL: 'https://sagolinkapp.firebaseio.com',
//   storageBucket: '',
//   messagingSenderId: '279406606917',
//   projectId: 'sagolinkapp',

//   // enable persistence by adding the below flag
//   persistence: true,
// };

export const androidConfig = {
  clientId: '279406606917-j5elnue6drj3ic66n9dal3qivmhq3vd0.apps.googleusercontent.com',
  appId: 'com.sagolinkapp',
  apiKey: 'AIzaSyBacb5L1oHea675YBw-XelzbTOcc9X4Pc4',
  databaseURL: '',
  storageBucket: '',
  messagingSenderId: '279406606917',
  projectId: 'sagolinkapp',

  // enable persistence by adding the below flag
  persistence: true,
};
