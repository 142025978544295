import { MedicalCenter, MedicalCenterType } from '../../../types';
import Select, {
  ControlProps,
  InputProps,
  ValueContainerProps,
  components,
} from 'react-select';

import React from 'react';
import { colors } from '../../../theme';
import styled from 'styled-components/native';
import { useAppContext } from '../../../providers/AppProvider';
import { useMediaQuery } from 'react-responsive';

const Wrapper = styled.View`
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  border-width: 0;
  border-color: red;
`;

export const Title = styled.Text`
  font-family: Noto Sans KR;
  font-size: 14;
  color: ${colors.black700};
`;

const customStyles = {
  controlStyles: {
    borderRadius: '1px solid black',
    padding: '0px',
    // background: 'green',
    // color: 'white',
  },
};

const CustomControl = (props: ControlProps<any>): React.ReactElement => {
  return (
    <div style={customStyles.controlStyles}>
      <components.Control {...props} />
    </div>
  );
};

const CustomInput = (props: InputProps): React.ReactElement => {
  return <components.Input {...props} isHidden={true} />;
};

const CustomValueContainer = (
  props: ValueContainerProps<any>,
): React.ReactElement => {
  return (
    <components.ValueContainer {...props}>
      {props.children}
    </components.ValueContainer>
  );
};

const CustomSelectContainer = (props: any): React.ReactElement => {
  return (
    <components.SelectContainer {...props}>
      {props.children}
    </components.SelectContainer>
  );
};

interface Props {
  medicalCenterType: MedicalCenterType;
  onSelectMedicalCenter: (medicalCenter: MedicalCenter) => void;
}

const AcciHospitalType = (props: Props): React.ReactElement => {
  const { state: { baseMedicalCenters } } = useAppContext();

  const isDesktop = useMediaQuery({
    query: '(min-device-width: 500px)',
  });
  const [hospitalValue, setHospitalValue] = React.useState();

  return (
    <Wrapper>
      <Title style={{ fontWeight: 'bold', marginBottom: 12 }}>
        {'의료기관 종류'}
      </Title>
      <div
        style={{
          // border: '1px solid red',
          padding: 0,
          width: '100%',
        }}
        id="selectorWrapper"
      >
        <Select
          // value={hospitalValue}
          value={baseMedicalCenters[props.medicalCenterType]}
          // value={baseMedicalCenters[props.medicalCenterType].value}

          onChange={(newValue: any, actionMeta: any): void => {
            props.onSelectMedicalCenter(newValue);
            // setHospitalValue(newValue);
          }}
          isSearchable={false}
          // menuIsOpen={menuIsOpen}
          // captureMenuScroll
          // onBlur={(event):void => event.preventDefault()}
          // closeMenuOnScroll={():boolean => true}
          menuShouldBlockScroll={true}
          // maxMenuHeight={300}
          // minMenuHeight={200}
          // captureMenuScroll={true}
          focusDefaultOption={false}
          openMenuOnFocus={true}
          menuShouldPortal={false}
          menuPlacement="auto"
          menuPosition="absolute"
          // autoFocus={false}
          placeholder="의료기관 종류를 선택해주세요."
          styles={{
            singleValue: (base): React.CSSProperties => ({
              ...base,
              color: colors.black400,
              fontSize: 14,
              fontWeight: 500,
            }),
            // valueContainer: (base): React.CSSProperties => ({
            //   ...base,
            //   color: 'white',
            //   width: '100%',
            // }),
            container: (base): React.CSSProperties => ({
              ...base,
              padding: 0,
            }),
            control: (base): React.CSSProperties => {
              const hoverStyle = {
                '&:hover': {
                  // display: 'none',
                  borderColor: 'transparent',
                },
              } as React.CSSProperties;
              return {
                ...base,
                ...hoverStyle,
                backgroundColor: colors.black100,
                borderRadius: '2px',
                padding: 3,
                color: colors.black700,
                fontSize: 14,
                border: '0px solid lightgray',
                boxShadow: 'none', // no box-shadow
              };
            },
            indicatorSeparator: (base): React.CSSProperties => ({
              ...base,
              backgroundColor: 'transparent',
              // padding: 5,
            }),
            menu: (base): React.CSSProperties => {
              const { zIndex, ...rest } = base;
              return {
                ...base,
                width: '100%',
                // overflow: 'hidden',
                // backgroundColor: colors.black200,
                // padding: 3,
                // border: '1px solid red',
                // zIndex: 9999,
                // overflow: 'scroll',
              };
            },
            // placeholder: (base): React.CSSProperties => ({
            //   ...base,
            //   color: colors.black400,
            //   fontSize: 14,
            // }),
            option: (base): React.CSSProperties => ({
              ...base,
              color: colors.black700,
              fontSize: 12,
              // height: 13,
              // lineHeight: 12,
              // border: '1px solid purple',
              // zIndex: 9999,
            }),
            menuPortal: (base): React.CSSProperties => ({
              ...base,
              // position: 'absolute',
              zIndex: 9999,
              // border: '1px solid red',
              // backgroundColor: colors.black700,
            }),
            menuList: (base): React.CSSProperties => {
              const webkitStyle = {
                '::-webkit-scrollbar': {
                  // display: 'none',
                  width: '3px',
                  height: '3px',
                  colors: 'red',
                },
              } as React.CSSProperties;
              return {
                ...base,
                padding: 0,
                scrollbarColor: 'red',
                // ...webkitStyle,
                // border: '5px solid blue',
                // zIndex: 9999,
                // flex: 1,
                // height: 200,
              };
            },
          }}
          // isClearable
          components={{
            Control: CustomControl,
            ValueContainer: CustomValueContainer,
            SelectContainer: CustomSelectContainer,
            Input: CustomInput,
          }}
          // isSearchable
          name="color"
          options={Object.values(baseMedicalCenters)}
          menuPortalTarget={document.body}
        />
      </div>
    </Wrapper>
  );
};

export default AcciHospitalType;
