import Modal from 'react-modal';
import React from 'react';
import { colors } from '../../../theme';

interface Props {
  modalOnOff: boolean;
  modalOff: () => void;
}

const InfoModal = (props: Props): React.ReactElement => {
  return (
    <>
      <Modal
        isOpen={props.modalOnOff}
        // onAfterOpen={afterOpenModal}
        onRequestClose={props.modalOff}
        style={{
          overlay: {
            position: 'absolute',
            flex: 1,
            backgroundColor: 'rgba(0, 0, 0, 0.6)',
          },
          content: {
            position: 'fixed',
            // display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            top: '50%',
            height: '50%',
            borderRadius: 10,
            borderWidth: 0,
            marginRight: '-5%',
            marginLeft: '-5%',
            padding: 0,
          },
        }}
        // closeTimeoutMS={2000}
        parentSelector={(): Element =>
          document.querySelector('#settlementmoneywrapper')
        }
        // classNames="Content"
        // overlayClassName="Overlay"
      >
        <div style={{ padding: 15, border: '0px solid red' }}>
          <div style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            paddingTop: '15px',
            paddingLeft: '15px',
            paddingRight: '15px',
            paddingBottom: '15px',
          }}>
            <div style={{
              fontFamily: 'Noto Sans KR',
              fontWeight: 700,
              fontSize: '20px',
              lineHeight: '24px',
            }}>{'향후 치료 기간'}</div>
            <div style={{
              fontFamily: 'Noto Sans KR',
              fontWeight: 400,
              fontSize: '14px',
              lineHeight: '20px',
              color: colors.black400,
              marginTop: 10,
            }}>
              {
                '합의금을 구성하는 항목 중에는 향후치료비 라는 항목이 있습니다. ' +
                '이는 향후에 치료받으며 발생되는 치료비를 합의금으로 대신 ' +
                '수령하는 금액을 말합니다. 앞으로 치료받을 기간, 즉, 향후 치료 ' +
                '기간을 교통사고 피해자가 직접 설정하여 이를 토대로 합의금의 ' +
                '향후치료비 항목을 계산하게 됩니다. 교통사고 피해자가 본인 ' +
                '몸상태에 따라 직접 설정할 수 있는 만큼, 실질적으로 합의금을 ' +
                '좌우한다고 할 수 있습니다. 하지만 과도한 향후 치료 기간 및 ' +
                '합의금 주장은 보험사에서 받아들이기 어려울 수 있으므로 ' +
                '주의바랍니다.'
              }
            </div>
            <div style={{
              color: colors.exitBlue,
              fontSize: '14px',
              fontFamily: 'Noto Sans KR',
              fontWeight: 400,
              alignSelf: 'flex-end',
              marginTop: '10px',
            }} onClick={props.modalOff}>{'닫기'}</div>
          </div>
        </div>
        {/* <ModalContentWrapper>
          <ModalTitle>{'향후 치료 기간'}</ModalTitle>
          <ModalText style={{ marginTop: 15 }}>
            {
              '합의금을 구성하는 항목 중에는 향후치료비 라는 항목이 있습니다. ' +
                '이는 향후에 치료받으며 발생되는 치료비를 합의금으로 대신 ' +
                '수령하는 금액을 말합니다. 앞으로 치료받을 기간, 즉, 향후 치료 ' +
                '기간을 교통사고 피해자가 직접 설정하여 이를 토대로 합의금의 ' +
                '향후치료비 항목을 계산하게 됩니다. 교통사고 피해자가 본인 ' +
                '몸상태에 따라 직접 설정할 수 있는 만큼, 실질적으로 합의금을 ' +
                '좌우한다고 할 수 있습니다. 하지만 과도한 향후 치료 기간 및 ' +
                '합의금 주장은 보험사에서 받아들이기 어려울 수 있으므로 ' +
                '주의바랍니다.'
            }
          </ModalText>
          <ModalButton onClick={props.modalOff}>{'닫기'}</ModalButton>
        </ModalContentWrapper> */}
      </Modal>
    </>
  );
};

export default InfoModal;
