import { Redirect, useHistory, useLocation } from 'react-router-dom';

import Checkcircle from 'react-native-vector-icons/AntDesign';
import DesktopWrapper from '../shared/DesktopWrapper';
import HomeLink from '../shared/HomeLink';
import React from 'react';
import SignInOut from '../shared/SignInOut';
import { colors } from '../../theme';
import { isBrowser } from 'react-device-detect';
import ko from 'date-fns/locale/ko';
import { registerLocale } from 'react-datepicker';
import styled from 'styled-components/native';

registerLocale('ko', ko);

const Container = styled.View`
  flex: 1;
  align-items: center;
  justify-content: center;
  border-width: 0;
  border-color: red;
  overflow: 'hidden';
`;

const MobileWrapper = styled.View`
  flex-grow: ${isBrowser ? 0 : 1};
  flex-shrink: ${isBrowser ? 0 : 1};
  flex-basis: ${isBrowser ? 'auto' : 0};
  width: ${isBrowser ? 360 : window.innerWidth};
  height: ${isBrowser ? 720 : window.innerHeight};
  overflow: hidden;
  border-width: 0;
  border-color: red;
`;

const MobileScrollView = styled.ScrollView`
  background-color: ${colors.white};
`;

const Wrapper = styled.View`
  flex-direction: column;
  align-items: flex-start;
  border-width: 1;
  border-color: red;
  padding-left: 20;
  padding-right: 20;
`;

export const AccentTitle = styled.Text`
  font-family: Noto Sans KR;
  font-size: 20;
  line-height: 26;
  font-weight: bold;
  color: ${({ theme }): string => theme.accentTitle};
`;

export const Title = styled.Text`
  font-family: Noto Sans KR;
  font-weight: bold;
  font-size: 14;
  color: ${colors.black700};
  margin-bottom: 10px;
`;

const ButtonWrapper = styled.View`
  flex-direction: row;
  width: 100%;
`;

const Button = styled.TouchableOpacity`
  width: 50%;
  height: 56px;
  background-color: ${colors.exitBlue};
  justify-content: center;
  align-items: center;
`;

const ButtonText = styled.Text`
  font-family: Noto Sans KR;
  font-size: 16;
  line-height: 22;
  font-weight: bold;
  color: ${colors.white};
`;

interface Props {
  testID?: string;
}
interface LocationState {
  resultMsg: string;
}
function SettlementMoneyTransmissionCompleteMobile(): React.ReactElement {
  const location = useLocation() as {state: LocationState};
  const resultMsg = location.state?.resultMsg;

  const gotoHome = (): void => {
    window.location.replace('http://www.sagolink.com');
  };
  const gotoAppLink = () : void => {
    if (isBrowser) {
      alert('모바일에서 실행해 주세요.');
    } else {
      window.location.assign('https://sagolinkapp.page.link/applink');
    }
  };

  return (
    <MobileWrapper>
      <MobileScrollView
        showsVerticalScrollIndicator={false}
        nestedScrollEnabled={false}
        style={{ height: '100%', borderWidth: 0, borderColor: 'red' }}
        contentContainerStyle={{ justifyContent: 'center', flex: 1 }}
      >
        <Wrapper
          style={{
            flex: 1,
            borderWidth: 0,
            borderColor: 'red',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Checkcircle name="checkcircle" size={42} color={colors.happyMint} />
          <AccentTitle
            style={{
              color: 'black',
              fontWeight: 'bold',
              borderWidth: 0,
              borderColor: 'red',
              marginTop: 10,
            }}
          >
            {resultMsg}
          </AccentTitle>
        </Wrapper>
      </MobileScrollView>
      <ButtonWrapper>
        <Button
          style={{ backgroundColor: '#002d91' }}
          onPress={gotoHome}
        >
          <ButtonText>{'홈으로 가기'}</ButtonText>
        </Button>
        <Button
          onPress={gotoAppLink}
        >
          <ButtonText>{'앱으로 가기'}</ButtonText>
        </Button>
      </ButtonWrapper>
    </MobileWrapper>
  );
}

const SettlementMoneyTransmissionComplete = (): React.ReactElement => {
  return (
    <Container>
      {isBrowser ? (
        <>
          <DesktopWrapper>
            <SettlementMoneyTransmissionCompleteMobile />
          </DesktopWrapper>
        </>
      ) : (
        <SettlementMoneyTransmissionCompleteMobile />
      )}
      {isBrowser ? <HomeLink /> : null}
      {isBrowser ? <SignInOut /> : null}
    </Container>
  );
};

export default SettlementMoneyTransmissionComplete;
