import { createGlobalStyle } from 'styled-components';
import reset from 'styled-reset';

const GlobalStyles = createGlobalStyle`
  ${reset};
  @import url('https://fonts.googleapis.com/css2?family=Do+Hyeon&family=Noto+Sans+KR:wght@100;300;400;500;700;900&display=swap');
  body {
    /* color: #333;
    font-weight: 300;
    font-size: 17px; */
    font-family: 'Noto Sans KR', 'Do Hyeon', sans-serif;
    /* line-height: 1.5; */
  }
`;
export default GlobalStyles;
