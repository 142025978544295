export const colors = {
  black: '#222222',
  white: '#ffffff',
  exitBlue: '#004fff',
  black100: '#efefef',
  black200: '#dddddd',
  black300: '#bbbbbb',
  black400: '#999999',
  black500: '#565656',
  black600: '#444444',
  black700: '#222222',
  happyMint: '#2ed29f',
  softBlue: '#5273ee',
  butterYellow: '#ffb21f',
  gloryRed: '#ff424c',

  dodgerBlue: 'rgb(58,139,255)',

  google: 'rgb(224,66,56)',
  facebook: 'rgb(52,89,151)',
};

export const light = {
  background: colors.white,
  btnBackground: colors.exitBlue,
  fontColor: colors.black700,
  fontGreyColor: colors.black300,
  fontGrey400: colors.black400,
  fontWhite: colors.white,
  indicatorColor: colors.black500,
  accentTitle: colors.exitBlue,
  textDisabled: colors.black500,
  btnPrimary: colors.white,
  btnDisabled: colors.black300,
  btnPrimaryFont: colors.black700,
};

export type Theme = typeof light;

export const dark = {
  background: colors.black700,
  btnBackground: colors.exitBlue,
  fontColor: colors.white,
  fontGreyColor: colors.black300,
  fontGrey400: colors.black400,
  fontWhite: colors.white,
  indicatorColor: colors.black500,
  accentTitle: colors.exitBlue,

  textDisabled: colors.black500,
  btnPrimary: '#262A2C',
  btnDisabled: colors.black300,
  btnPrimaryFont: 'white',
};
