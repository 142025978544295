import {
  Animated,
  Easing,
  View,
} from 'react-native';
import { EstimatedMoneyStatus, EstimatedMoneyStatusCode } from '../../types';

import { ActivityIndicator } from 'react-native-paper';
import { CommonImages } from '../../utils/DesignAssets';
import React from 'react';
import VectorIcons from 'react-native-vector-icons/FontAwesome5';
import { colors } from '../../theme';
import {
  isBrowser,
} from 'react-device-detect';
import {
  screenWidth,
} from '../../utils/Styles';
import styled from 'styled-components/native';

const SideTabButton = styled.TouchableOpacity`
  background-color: ${colors.softBlue};
  width: 26;
  height: 68;
  border-bottom-left-radius: 16;
  border-top-left-radius: 16;
  justify-content: center;
  align-items: center;
  z-index: 10;
  /* left: ${screenWidth - 26}; */
`;

const BoardTextRowWrapper = styled.View`
  flex-direction: row;
  align-items: center;
  border-width: 0;
  border-color: red;
  flex: 1;
`;

const BoardText = styled.Text`
  font-family: Noto Sans KR;
  font-size: 11px;
  line-height: 18px;
  color: ${colors.black700};
`;

const InnerCircle = styled.View`
  position: absolute;
  width: 38;
  height: 38;
  border-width: 4.2;
  border-radius: 19;
  border-color: ${colors.black200};
`;

const WonImage = styled.Image`
  position: absolute;
  left: 10.5;
  width: 18;
  height: 18;
`;

const StatusTextWrapper = styled.View`
  flex: 1;
  flex-direction: row;
  justify-content: center;
  /* justify-content: space-around; */
`;
const StatusCode = styled.View`
  /* flex-direction: row; */
  justify-content: space-around;
`;

const Divider = styled.View`
  width: 2px;
  height: 36px;
  background-color: ${colors.black100};
  margin-right: 2;
  margin-left: 2;
`;

interface Props {
  statusbarLoading: boolean;
  estimatedMoneyStatus?: EstimatedMoneyStatus;
  trigger?: boolean; // true, false 변경 시 open
}

function CalculatingStatus(props: Props): React.ReactElement {
  const [initialized, setInitialized] = React.useState<boolean>(false); // 최초 렌더링 완료 여부 (최초에는 오픈하지 않기 위해서)
  const timerRef = React.useRef<NodeJS.Timeout>();

  // const [aniValue, setAniValue] = React.useState(new Animated.Value(0));
  const [aniValue, setAniValue] = React.useState(new Animated.Value(0));
  const openButtonWidth = 26;
  const closeDelayTime = 3000; // 보여주는 시간

  const closeStatusBar = (): void => {
    // 상태바 닫기
    Animated.timing(aniValue, {
      toValue: 0,
      duration: 500,
      useNativeDriver: true,
      easing: Easing.bezier(0.52, 0.3, 0.28, 0.96),
    }).start();
  };
  const openStatusBar = (): void => {
    // 상태바 열기
    // 이미 열려 있는 상태에서는 열기를 실행하지 않고,
    // 기존 닫기를 취소하고, 새 닫기를 예약한다.
    console.log('>>> timerRef = ', timerRef.current);
    if (timerRef.current) {
      clearTimeout(timerRef.current);
      timerRef.current = undefined;
    } else {
      Animated.timing(aniValue, {
        toValue: 1,
        duration: 500,
        useNativeDriver: true,
        easing: Easing.bezier(0.52, 0.3, 0.28, 0.96),
      }).start();
    }
    timerRef.current = setTimeout(() => {
      console.log('>>>>>> 상태바 닫음.... ');
      closeStatusBar();
      timerRef.current = undefined;
    }, closeDelayTime);
  };

  // animation 스타일
  const statusBarStyle = {
    transform: [
      {
        translateX: aniValue.interpolate({
          inputRange: [0, 1],
          outputRange: [
            (isBrowser ? 360 : window.innerWidth) - openButtonWidth,
            -openButtonWidth,
          ],
        }),
      },
    ],
  };
  console.log('>>>>>>>>>>>>> initialized = ', initialized);

  React.useEffect(() => {
    // console.log('>>>>>>>>>>>>> initialized = ', initialized);
    if (initialized) {
      openStatusBar();
    }
    !initialized &&
      setTimeout(() => {
        setInitialized(true);
      }, 100);

    // return (): void => {
    //   timerRef.current && clearTimeout(timerRef.current);
    // };
  }, [props.trigger]);

  const circleIcon =
    props.estimatedMoneyStatus?.statusCode ===
    EstimatedMoneyStatusCode.needInfo ? (
        <VectorIcons
          name={'exclamation-circle'}
          size={38}
          color={colors.black600}
        />
      ) : props.estimatedMoneyStatus?.statusCode ===
      EstimatedMoneyStatusCode.fault ? (
          <VectorIcons
            name={'exclamation-circle'}
            size={38}
            color={colors.gloryRed}
          />
        ) : props.estimatedMoneyStatus?.statusCode ===
      EstimatedMoneyStatusCode.more ? (
            <VectorIcons
              name={'exclamation-circle'}
              size={38}
              color={colors.butterYellow}
            />
          ) : props.estimatedMoneyStatus?.statusCode ===
      EstimatedMoneyStatusCode.most ? (
              <VectorIcons
                name={'exclamation-circle'}
                size={38}
                color={colors.gloryRed}
              />
            ) : props.estimatedMoneyStatus?.statusCode ===
      EstimatedMoneyStatusCode.less ? (
                <VectorIcons
                  name={'exclamation-circle'}
                  size={38}
                  color={colors.butterYellow}
                />
              ) : (
                <VectorIcons
                  name={'exclamation-circle'}
                  size={38}
                  color={colors.happyMint}
                />
              );

  const aboutMoney = props.estimatedMoneyStatus?.aboutMoney || 0;
  const moneyString =
    aboutMoney < 1000000
      ? '100만원 이하'
      : aboutMoney >= 7000000
        ? '700만원대 이상'
        : `약 ${aboutMoney / 10000}만원 대`;
  const statusCode =
    props.estimatedMoneyStatus?.statusCode === EstimatedMoneyStatusCode.less
      ? {
        text: '평균 이하',
        color: colors.butterYellow,
        money: moneyString,
      }
      : props.estimatedMoneyStatus?.statusCode ===
        EstimatedMoneyStatusCode.average
        ? {
          text: '적정 수준',
          color: colors.happyMint,
          money: moneyString,
        }
        : props.estimatedMoneyStatus?.statusCode === EstimatedMoneyStatusCode.more
          ? {
            text: '평균 이상',
            color: colors.butterYellow,
            money: moneyString,
          }
          : props.estimatedMoneyStatus?.statusCode === EstimatedMoneyStatusCode.most
            ? {
              text: '평균 초과',
              color: colors.gloryRed,
              money: moneyString,
            }
            : {};

  return (
    // <SideTabWrapper pointerEvents={'auto'} style={{ borderWidth: 1, borderColor: 'red' }}>
    <Animated.View
      style={[
        {
          position: 'absolute',
          width: (isBrowser ? 360 : window.innerWidth) + openButtonWidth,
          // backgroundColor: colors.white,
          borderWidth: 0,
          borderColor: 'red',
          flexDirection: 'row',
          overflow: 'hidden',
          zIndex: 99,
        },
        statusBarStyle,
      ]}
    >
      <SideTabButton onPress={openStatusBar}>
        <VectorIcons name="angle-left" size={17} color={colors.white} />
      </SideTabButton>
      <View
        style={{
          flex: 1,
          // width: '100%',
          height: 68,
          flexDirection: 'row',
          justifyContent: 'flex-start',
          paddingLeft: 15,
          alignItems: 'center',
          borderTopWidth: 1,
          borderTopColor: colors.black100,
          borderWidth: 0,
          borderColor: colors.black300,
          // position: 'absolute',
          // left: screenWidth,
          backgroundColor: '#fff',
          shadowColor: '#000',
          shadowOffset: { width: 0, height: 1 },
          shadowOpacity: 0.27,
          shadowRadius: 4.65,
          elevation: 5,
          zIndex: 5,
          marginBottom: 10,
        }}
      >
        {props.statusbarLoading ? (
          <BoardTextRowWrapper>
            <InnerCircle />
            <WonImage source={CommonImages.Won} />
            <ActivityIndicator
              animating={true}
              size={38}
              color={colors.exitBlue}
            />
            <BoardText
              allowFontScaling={false}
              style={{
                color: colors.black400,
                marginLeft: 14,
              }}
            >
              {'계산 중 ∙∙∙'}
            </BoardText>
          </BoardTextRowWrapper>
        ) : (
          <BoardTextRowWrapper>
            {circleIcon}
            <StatusTextWrapper style={{
              width: '100%',
              borderWidth: 0,
              borderColor: 'red',
              justifyContent: 'center',
              alignItems: 'center',
            }}>
              {statusCode.text ? (
                <>
                  <StatusCode
                    style={{
                      width: 130,
                      borderWidth: 0,
                      borderColor: 'blue',
                      marginLeft: 5,
                    }}
                  >
                    <BoardText style={{ fontSize: 11 }}>
                      {'예상 합의금 '}
                      <BoardText
                        style={{ fontSize: 11, color: statusCode.color }}
                      >
                        {statusCode.text}
                      </BoardText>
                    </BoardText>
                    <BoardText
                      style={{ fontSize: 16, lineHeight: 20, marginTop: 3 }}
                    >
                      {statusCode.money}
                    </BoardText>
                  </StatusCode>
                  <Divider />
                </>
              ) : null}
              <StatusCode
                style={{
                  // width: 160,
                  flex: 1,
                  borderWidth: 0,
                  borderColor: 'blue',
                  paddingHorizontal: 10,
                }}
              >
                <BoardText style={{ flexWrap: 'wrap' }} numberOfLines={2}>
                  {props.estimatedMoneyStatus?.statusText}
                </BoardText>
              </StatusCode>
            </StatusTextWrapper>
          </BoardTextRowWrapper>
        )}
      </View>
    </Animated.View>
    // </SideTabWrapper>
  );
}

export default CalculatingStatus;
