import { AppLoading, Asset } from 'expo';
import { GlobalLoadingPresenter, GlobalLoadingProvider } from './providers/GlobalLoadingProvider';
import React, { useState } from 'react';
import { SagocareProvider, useSagocareContext } from './providers/SagocareProvider';
import { User, UserPayload } from './types';

import GlobalStyles from './GlobalStyles';
import Icons from './utils/Icons';
import { Image } from 'react-native';
import LoadingCover from './components/shared/LoadingCover';
import RootProvider from './providers';
import SwitchNavigator from './components/navigation/SwitchNavigator';
import firebase from 'firebase';
import { useAppContext } from './providers/AppProvider';
import { useAuthState } from 'react-firebase-hooks/auth';
import { webConfig } from '../config';

if (!__DEV__) {
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  console.log = function noConsole(): any {};
}

// Initialize Firebase
if (!firebase.apps.length) {
  firebase.initializeApp(webConfig);
  // firebase.analytics();
}

function cacheImages(images: Image[]): Image[] {
  return images.map((image: Image) => {
    if (typeof image === 'string') {
      return Image.prefetch(image);
    } else {
      return Asset.fromModule(image).downloadAsync();
    }
  });
}

const loadAssetsAsync = async (): Promise<void> => {
  const imageAssets = cacheImages(Icons);
  await Promise.all([...imageAssets]);
};

function App(): React.ReactElement {
  const [authUser] = useAuthState(firebase.auth() as any);
  const { state: { user }, setUser, resetUser } = useAppContext();

  React.useEffect(() => {
    document.body.style.backgroundColor = '#002d91';
    // document.body.style.width = '100%';
    // document.body.style.height = '100%';
    // document.body.style.border = '1em solid green';
    // document.body.style.display = 'flex';
    // document.body.style.flexDirection = 'column';
    // document.body.style.justifyContent = 'center';
    // document.body.style.alignItems = 'center';
  }, []);

  // UserStore 정보 읽고, provider 세팅하기
  const loadIdTokenResult = async (authUser: firebase.User): Promise<void> => {
    const idTokenResult = await authUser?.getIdTokenResult();

    const userInfo: UserPayload = idTokenResult.claims._userInfo; // SNS 로그인 시 or 회원가입 시 저장된 유저 기본 정보

    const user: User = {
      uid: authUser.uid,
      userName: userInfo?.displayName || authUser.displayName || '',
      email: userInfo?.email || authUser.email || '',
      phoneNumber: userInfo?.phoneNumber || authUser.phoneNumber || '',
      photoURL: userInfo?.photoURL || authUser.photoURL || '',
      thumbURL: userInfo?.photoThumnail || authUser.photoURL || '',
      signedup: userInfo?.customSignedUp,
      snsLoginType: userInfo?.snsLoginType,
    };
    // console.log('>>> authUser :: loggedin user = ', user);
    setUser(user);
  };

  // authUser :: 인증 제어
  React.useEffect(() => {
    if (authUser) {
      console.log('>>> firebase auth ', firebase.auth());
      // 로그인 되었음. 이제 유저 정보 로딩한다. (로긴될 때 1회만)
      console.log('>> authUser = ', JSON.stringify(authUser));

      loadIdTokenResult(authUser);
    } else {
      // 로그아웃 하면 해야할 일 : resetUser, sagocareReset
      resetUser();
      // unsubscribe && unsubscribe();
    }
  }, [authUser, user.uid]);

  return (
    <>
      <SwitchNavigator />
      <LoadingCover />
    </>
  );
  // return <SwitchNavigator />;
}

function ProviderWrapper(): React.ReactElement {
  const [loading, setLoading] = useState(false);

  if (loading) {
    return (
      <AppLoading
        startAsync={loadAssetsAsync}
        onFinish={(): void => setLoading(true)}
        // onError={console.warn}
      />
    );
  }
  return (
    <>
      <GlobalStyles />
      <RootProvider>
        <GlobalLoadingProvider>
          <SagocareProvider>
            <App />
          </SagocareProvider>
        </GlobalLoadingProvider>
      </RootProvider>
    </>
  );
}

export default ProviderWrapper;
