import { TextStyle, ViewStyle } from 'react-native';
import React from 'react';
import { colors } from '../../theme';
import styled from 'styled-components/native';

const InfoInputButton = styled.TouchableOpacity`
  flex-direction: row;
  background-color: #002d91;
  justify-content: space-between;
  align-items: center;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 15px;
  padding-bottom: 15px;
  /* height: 70px; */
  width: 100%;
  border-radius: 10px;
  border-width: 0;
  border-color: red;
`;

const ButtonText = styled.Text`
  font-family: Noto Sans KR;
  font-size: 14px;
  line-height: 20px;
  color: ${colors.white};
`;

const InsideButton = styled.View`
  background-color: ${colors.exitBlue};
  padding-left: 6px;
  padding-right: 6px;
  height: 25px;
  border-radius: 5px;
  justify-content: center;
  align-items: center;
  padding-bottom: 1;
`;

const InsideButtonText = styled.Text`
  font-family: Noto Sans KR;
  font-size: 12px;
  line-height: 14px;
  color: ${colors.white};
  font-weight: 600;
  top: 3px;
`;

interface Props {
  style?: ViewStyle;
  textStyle?: TextStyle;
  text: string;
  buttonText: string;
  // icon: ImageSourcePropType;
  onClick?: () => void;
}

function SagoCareInfoInputButton(props:Props): React.ReactElement {
  return (
    <>
      <InfoInputButton
        style={{ ...props.style }}
        onPress={props.onClick}
      >
        <ButtonText>
          {props.text}
        </ButtonText>

        <InsideButton>
          <InsideButtonText>
            {props.buttonText}
          </InsideButtonText>
        </InsideButton>
      </InfoInputButton>
    </>
  );
}

export default SagoCareInfoInputButton;
