import { ImageSourcePropType, TextStyle, ViewStyle } from 'react-native';
import React from 'react';
import { colors } from '../../theme';
import styled from 'styled-components/native';
import { useNavigation } from '@react-navigation/native';

const CardWrapper = styled.TouchableOpacity`
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: #fafafa;
  padding-left: 20px;
  padding-right: 20px;
  border-radius: 10px;
  height: 70px;
  width: 100%;
`;

const CardIcon = styled.Image`
  width: 30px;
  height: 30px;
`;

const CardText = styled.Text`
  font-family: Noto Sans KR;
  font-weight: 600;
  color: ${colors.black700};
  font-size: 14px;
  line-height: 20px;
  width: 60%;
  border-width: 0;
  border-color: red;
  padding-left: 20px;
`;

const CardButton = styled.View`
  background-color: ${colors.black200};
  justify-content: center;
  align-items: center;
  padding-right: 10px;
  padding-left: 10px;
  height: 25px;
  border-radius: 5px;
  padding-bottom: 2;
`;

const CardButtonText = styled.Text`
  font-family: Noto Sans KR;
  font-weight: bold;
  color: ${colors.black400};
  font-size: 10;
`;

interface Props {
  style?: ViewStyle;
  textStyle?: TextStyle;
  icon: ImageSourcePropType;
  text: string;
  onClick: () => void;
}

function SagoCareCard(props:Props): React.ReactElement {
  return (
    <CardWrapper
      style={{ ...props.style }}
      onPress={props.onClick}
    >
      <CardIcon source={props.icon} />
      <CardText
        style={{ ...props.textStyle }}
      >
        {props.text}
      </CardText>
      <CardButton>
        <CardButtonText style={{ lineHeight: 20, textAlignVertical: 'center' }}>
          {'앱 설치하기'}
        </CardButtonText>
      </CardButton>
    </CardWrapper>
  );
}

export default SagoCareCard;
