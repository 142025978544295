import { CloseCircle } from '../../../utils/Icons';
import React from 'react';
import { WoundedDetail } from '../../../types';
import { WoundedPartSmallImages } from '../../../utils/DesignAssets';
import styled from 'styled-components/native';
import { useAppContext } from '../../../providers/AppProvider';

const WoundedBodyPartCardWrapper = styled.View`
  margin-bottom: 5px;
  align-self: center;
  width: 100%;
  height: 60px;
  border-radius: 10px;
  border-color: #efefef;
  border-width: 1px;
  justify-content: center;
`;

const WoundedBodyPartCardRowWrapper = styled.View`
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
`;

const WoundedBodyPartCardRowWrapperLeft = styled.View`
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  border-color: red;
  width: 120px;
  border-width: 0px;
`;

const WoundedBodyPartImage = styled.Image`
  width: 40px;
  height: 40px;
`;

const WoundedBodyPartLabel = styled.Text`
  font-family: Noto Sans KR;
  font-size: 12px;
  color: #222222;
  width: 45%;
`;

const CardShortDivider = styled.View`
  height: 16px;
  background-color: #dddddd;
  width: 1px;
`;

const WoundedBodyPartCardRowWrapperRight = styled.View`
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  border-color: red;
  border-width: 0px;
  width: 160px;
`;

const WoundedBodyPartDetailLabel = styled.Text`
  font-family: Noto Sans KR;
  font-size: 12px;
  color: #222222;
  width: 55%;
  border-color: red;
  border-width: 0px;
`;

const DeleteButton = styled.TouchableOpacity``;

const DeleteIcon = styled.Image`
  width: 24px;
  height: 24px;
`;

interface Props {
  woundedDetail: WoundedDetail;
  onDelete?: (woundedDetail: WoundedDetail) => void;
  hide: boolean;
}

const AcciWoundedPart = (props: Props): React.ReactElement => {
  const { state: { baseAccidentCategories, baseAccidentForms, baseWoundedParts } } = useAppContext();

  return (
    <WoundedBodyPartCardWrapper>
      <WoundedBodyPartCardRowWrapper>
        <WoundedBodyPartCardRowWrapperLeft>
          <WoundedBodyPartImage
            source={WoundedPartSmallImages[props.woundedDetail.woundedPartType]}
          />
          <WoundedBodyPartLabel>
            {baseWoundedParts[props.woundedDetail.woundedPartType].label}
          </WoundedBodyPartLabel>
        </WoundedBodyPartCardRowWrapperLeft>
        <CardShortDivider />
        <WoundedBodyPartCardRowWrapperRight>
          <WoundedBodyPartDetailLabel>
            {props.woundedDetail.label}
          </WoundedBodyPartDetailLabel>
          <DeleteButton
            onPress={(): void => {
              props.onDelete && props.onDelete(props.woundedDetail);
            }}
          >
            <DeleteIcon source={CloseCircle} />
          </DeleteButton>
        </WoundedBodyPartCardRowWrapperRight>
      </WoundedBodyPartCardRowWrapper>
    </WoundedBodyPartCardWrapper>
  );
};

export default AcciWoundedPart;
