import './react-datepicker.css';

import DatePicker, { registerLocale } from 'react-datepicker';

import React from 'react';
import VectorIcons from 'react-native-vector-icons/AntDesign';
import { colors } from '../../../theme';
import ko from 'date-fns/locale/ko';
import moment from 'moment';
import styled from 'styled-components/native';

registerLocale('ko', ko);

const DatePickerWrapper = styled.View`
  width: 100%;
`;

const DatePickerButtonWrapper = styled.TouchableOpacity`
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 40px;
  background-color: ${colors.black100};
  padding: 14px;
  border-radius: 2px;
  /* border-width: 1;
  border-color: red; */
`;

const DatePickerButtonText = styled.Text`
  font-family: Noto Sans KR;
  font-size: 13px;
  line-height: 20px;
  color: ${colors.black400};
`;

interface Props {
  testID?: string;
  title?: string;
  isDisabled?: boolean;
  onSelect?: (date: Date) => void;
  accidentDate?: string; // JSON
}

const AccidentDate = (props: Props): React.ReactElement => {
  const [date, setDate] = React.useState();
  const DatePickerRef = React.useRef(null);
  const onChange = (selectedDate): void => {
    const currentDate = selectedDate || date;
    setDate(currentDate);
    props.onSelect && props.onSelect(currentDate);
  };

  return (
    <DatePickerWrapper>
      <DatePicker
        popperPlacement={'bottom'}
        ref={DatePickerRef}
        locale="ko"
        dateFormat="yy/mm/ddd"
        dateFormatCalendar="yyyy년 LLLL"
        placeholderText={'사고가 난 날짜를 입력해주세요.'}
        onChange={onChange}
        maxDate={new Date()}
        value={date}
        inline={false}
        isClearable={false}
        openToDate={date}
        // shouldCloseOnSelect={false}
        customInput={
          <DatePickerButtonWrapper
            style={{ width: '100%' }}
          >
            <DatePickerButtonText>
              {props.accidentDate ? moment(date).locale('ko').format('YYYY년 MM월 DD일 (ddd)') : '사고가 난 날짜를 입력해주세요.'}
            </DatePickerButtonText>
            <VectorIcons name="calendar" size={18} color={colors.black400} />
          </DatePickerButtonWrapper>
        }
      />
    </DatePickerWrapper>
  );
};

export default AccidentDate;
