// import ProfileDefault from '../../assets/commonImages/profile_default.png';

export const WoundedPartLargeImages = {
  Body: require('../../assets/commonImages/body.png'),
  headNeck: require('../../assets/commonImages/largeHeadNeck.png'),
  shoulderCollarbone: require('../../assets/commonImages/largeShoulder.png'),
  waistRib: require('../../assets/commonImages/largeWaistRib.png'),
  armElbow: require('../../assets/commonImages/largeArmElbow.png'),
  pelvis: require('../../assets/commonImages/largePelvis.png'),
  handWrist: require('../../assets/commonImages/largeHandWrist.png'),
  legKnee: require('../../assets/commonImages/largeLegKnee.png'),
  footAnkle: require('../../assets/commonImages/largeFootAnkle.png'),
};

export const WoundedPartSmallImages = {
  headNeck: require('../../assets/commonImages/smallHeadNeck.png'),
  shoulderCollarbone: require('../../assets/commonImages/smallShoulder.png'),
  waistRib: require('../../assets/commonImages/smallWaistRib.png'),
  armElbow: require('../../assets/commonImages/smallArmElbow.png'),
  pelvis: require('../../assets/commonImages/smallPelvis.png'),
  handWrist: require('../../assets/commonImages/smallHandWrist.png'),
  legKnee: require('../../assets/commonImages/smallLegKnee.png'),
  footAnkle: require('../../assets/commonImages/smallFootAnkle.png'),
};

export const WoundedPartLine = {
  headNeck: require('../../assets/commonImages/HeadNeckLine.png'),
  shoulderCollarbone: require('../../assets/commonImages/ShoulderCollarboneLine.png'),
  waistRib: require('../../assets/commonImages/WaistRibLine.png'),
  armElbow: require('../../assets/commonImages/ArmElbowLine.png'),
  pelvis: require('../../assets/commonImages/PelvisLine.png'),
  handWrist: require('../../assets/commonImages/HandWristLine.png'),
  legKnee: require('../../assets/commonImages/LegKneeLine.png'),
  footAnkle: require('../../assets/commonImages/FootAnkleLine.png'),
};

export const CommonImages = {
  // LoadingBg: require('../../assets/commonImages/loading_bg.png'),
  // CareTipBg: require('../../assets/commonImages/care_tip_bg.png'),
  // CareTipHospitalBg: require('../../assets/commonImages/care_tip_hospital_bg.png'),
  // CareTipNoneBg: require('../../assets/commonImages/care_tip_none_bg.png'),
  // ForecastMoneyBg: require('../../assets/commonImages/forecast_money_bg.png'),
  // ForecastMoneyBgSagoCare: require('../../assets/commonImages/forecast_money_bg_sagocare.png'),
  // ChatbotBg: require('../../assets/commonImages/chatbot_bg.png'),
  // AdjusterBg: require('../../assets/commonImages/adjuster_bg.png'),
  // AdjusterDefault: require('../../assets/commonImages/adjusterDefault.png'),
  // SagocareIntroBg: require('../../assets/commonImages/sagocareintro.png'),
  Won: require('../../assets/commonImages/won.png'),
  // LoginPageImage: require('../../assets/commonImages/loginPage.png'),
  // NoContent: require('../../assets/commonImages/no_content.png'),
  // NoNotification: require('../../assets/commonImages/no_notification.png'),
  MainLogo: require('../../assets/commonImages/logo.png'),
  // MoneyLogo: require('../../assets/commonImages/moneyLogo.png'),
};

// export const LoginButtons = {
//   kakao: require('../../assets/loginButtons/kakao_account_login_btn_large_narrow.png'),
//   apple: require('../../assets/loginButtons/kakao_account_login_btn_large_narrow.png'),
// };

// export const CurrentPositionButton = {
//   // background: require('../../assets/commonImages/curPosition_background.png'),
//   location: require('../../assets/commonImages/curPosition_location.png'),
//   myLocation: require('../../assets/commonImages/my_location.png'),
// };

// export default {
//   ImgProfileDefault: ProfileDefault,
// };
