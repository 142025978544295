import add14days from '../../assets/icons/add14days.png';
import add21days from '../../assets/icons/add21days.png';
import add28days from '../../assets/icons/add28days.png';
import add7days from '../../assets/icons/add7days.png';
import adjust from '../../assets/icons/adjust.png';
import closecircle from '../../assets/icons/close-circle.png';
import guide from '../../assets/icons/guide.png';
import infoicon from '../../assets/icons/blue-line.png';
import kakaoIcon from '../../assets/icons/kakao.png';
import selected from '../../assets/icons/selected.png';
import unselected from '../../assets/icons/unselected.png';

export const AccidentCategoryIcons = {
  car2man: require('../../assets/icons/accident_category_car2man.png'),
  car2car: require('../../assets/icons/accident_category_car2car.png'),
  car2bike: require('../../assets/icons/accident_category_car2bike.png'),
  car2etc: require('../../assets/icons/accident_category_car2etc.png'),
};
export const signinIcons = {
  kakaoLogin: require('../../assets/icons/kakao_login_icon.png'),
  appleLogin: require('../../assets/icons/apple_login_icon.png'),
};

// export const CalendarIcon = calendarIcon;
export const KakaoIcon = kakaoIcon;
export const Unselected = unselected;
export const Selected = selected;
// export const Left = left;
// export const Right = right;
export const GuideIcon = guide;
// export const CalculatorIcon = calculatorIcon;
// export const HospitalIcon = hospital;
export const AdjustIcon = adjust;
// export const Warning = warning;
export const Add7days = add7days;
export const Add14days = add14days;
export const Add21days = add21days;
export const Add28days = add28days;
// export const IC_MASK = MASK;
// export const Minus = minus;
// export const Plus = plus;
export const CloseCircle = closecircle;
// export const Up = up;
// export const Down = down;
// export const Close = close;
// export const SvgHome = svgHome;
// export const Pin = pin;
// export const Bell = bell;
export const Infoicon = infoicon;
// export const BellRedDot = bellRedDot;
// export const ArrowCircleRight = arrowCircleRight;
// export const MapCurDeviceLocation = mapCurDeviceLocation;
// export const MintPlus = mintPlus;
// export const GrayDown = grayDown;

export default [
  // IC_MASK,
];
