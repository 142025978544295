import * as baseDataApi from '../../apis/baseDataApi';

import React, { useState } from 'react';

import { ActivityIndicator } from 'react-native-paper';
import { colors } from '../../theme';
import { statusBarHeight } from '../../utils/Styles';
import styled from 'styled-components/native';
import { useAppContext } from '../../providers/AppProvider';

// background-color: ${({ theme }): string => theme.background};
const Container = styled.View`
  width: 100%;
  height: 100%;
  position: absolute;
  align-self: stretch;
  overflow: scroll;
  background-color: rgba(0,0,0,0.6);
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  padding-top: ${statusBarHeight};
`;

const StyledText = styled.Text`
  font-size: 20;
  line-height: 24;
  color: ${colors.white};
  font-family: Noto Sans KR;
  font-weight: bold;
  margin-top: 20;
`;

interface Props {
  testId?: string;
}

function LoadingCover(props: Props): React.ReactElement {
  const {
    state: { AdmDistricts, baseAccidentForms },
    setBaseAccidentCategories,
    setBaseAccidentForms,
    setBaseWoundedParts,
    setBaseWoundedDetails,
    setBaseMedicalCenters,
  } = useAppContext();
  const [isLoaded, setIsLoaded] = useState(false); // 기본 데이터 로드 시 true
  const [completedSlot, setCompletedSlot] = useState(true); // 슬롯머신 완료 시 true

  // 사고 세부 유형 데이터 로드
  const loadBaseAccidentForms = async (): Promise<void> => {
    const baseAccidentForms = await baseDataApi.fetchBaseAccidentForms();
    setBaseAccidentForms(baseAccidentForms);
  };

  // 사고 유형 데이터 로드
  const loadBaseAccidentCategories = async (): Promise<void> => {
    const baseAccidentCategories = await baseDataApi.fetchBaseAccidentCategory();
    setBaseAccidentCategories(baseAccidentCategories);
    console.log('>>> 사고유형 로드 ', baseAccidentCategories);
  };

  // 부상 부위 데이터 로드
  const loadBaseWoundedParts = async (): Promise<void> => {
    const baseWoundedParts = await baseDataApi.fetchBaseWoundedParts();
    setBaseWoundedParts(baseWoundedParts);
  };
  // 상세 부상부위 데이터 로드
  const loadBaseWoundedDetails = async (): Promise<void> => {
    const baseWoundedDetails = await baseDataApi.fetchBaseWoundedDetails();
    setBaseWoundedDetails(baseWoundedDetails);
  };
  // 의료기관 데이터 로드
  const loadMedicalCenters = async (): Promise<void> => {
    const baseMedicalCenters = await baseDataApi.fetchBaseMedicalCenters();
    setBaseMedicalCenters(baseMedicalCenters);
  };
  const loadBaseDatas = async (): Promise<void> => {
    console.log('나오나???');
    try {
      await Promise.all([
        loadBaseAccidentCategories(), // 사고유형
        loadBaseAccidentForms(), // 사고유형 상세
        loadBaseWoundedParts(), // 부상부위
        loadBaseWoundedDetails(), // 부상부위 상세
        loadMedicalCenters(), // 의료기관 종류
      ]);
      // console.log('>> Completed loading and setup base datas');
    } catch (err) {
      console.error('>> loadBaseDatas', err);
    }
    setIsLoaded(true);
  };

  React.useEffect(() => {
    if (!isLoaded) {
      loadBaseDatas();
    }
  }, [isLoaded]);
  if (isLoaded) {
    return null;
  } else {
    return (
      <Container >
        <ActivityIndicator color={colors.white}/>
        <StyledText>{'합의금 계산 준비 중'}</StyledText>
      </Container>
    );
  }
}

export default LoadingCover;
