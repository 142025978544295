import {
  TextInput as EditText,
  Text,
  TouchableOpacity,
  View,
} from 'react-native';

import Icon from 'react-native-vector-icons/FontAwesome';
import React from 'react';
import { colors } from '../../../theme';
import styled from 'styled-components/native';

const StyledSplitBar = styled.View`
  align-self: center;
  width: 100%;
`;

const Wrapper = styled.View`
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  border-width: 0;
  border-color: red;
`;

interface Props {
  testID?: string;
  title?: string;
  isDisabled?: boolean;
  onClick?: () => void;
  stayHospitalDays?: number;
  goingHospitalDays?: number;
  onStayHospitalDays?: (days: number) => void; // 입원일수 변경 시
  onGoingHospitalDays?: (days: number) => void; // 통원일수 변경 시
}

const AcciTreatmentSituation = (props: Props): React.ReactElement => {
  const updateStayHospitalDays = (days): void => {
    // 입원일수 업데이트
    props.onStayHospitalDays && props.onStayHospitalDays(days);
  };
  const updateOnGoingHospitalDays = (days): void => {
    // 통원일수 업데이트
    props.onGoingHospitalDays && props.onGoingHospitalDays(days);
  };

  return (
    <>
      <Wrapper>
        <View
          style={{
            width: '100%',
            height: 62,
            backgroundColor: '#efefef',
            borderColor: '#dddddd',
            borderRadius: 2,
            borderWidth: 0.7,
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <View
            style={{
              width: '90%',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Text
              style={{
                fontFamily: 'Noto Sans KR',
                fontSize: 14,
              }}
            >
              입원일수
            </Text>
            <View
              style={{
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'space-between',
                width: 126,
                height: 28,
                backgroundColor: 'white',
              }}
            >
              <TouchableOpacity
                onPress={(): void => {
                  updateStayHospitalDays(
                    props.stayHospitalDays ? props.stayHospitalDays - 1 : 0,
                  );
                }}
                disabled={
                  props.isDisabled ||
                  !props.stayHospitalDays ||
                  props.stayHospitalDays === 0
                }
                style={{
                  backgroundColor:
                    !props.stayHospitalDays ||
                    props.stayHospitalDays === 0 ||
                    props.isDisabled
                      ? colors.black200
                      : colors.black600,
                  width: 30,
                  height: 28,
                  justifyContent: 'center',
                  alignItems: 'center',
                  borderWidth: 0,
                  borderColor: 'red',
                }}
              >
                <Icon
                  name="minus"
                  size={18}
                  color={colors.white}
                  style={{ top: 0.8, zIndex: 1 }}
                />
              </TouchableOpacity>

              {/* <Text>{props.stayHospitalDays ? props.stayHospitalDays : 0}</Text> */}
              <EditText
                testID="stay-hospital-days"
                // errorTestID="error-name"
                // textInputProps={{ allowFontScaling: false }}
                // textStyle={{
                //   fontSize: 25,
                //   color: colors.black700,
                //   textAlign: 'center',
                //   textAlignVertical: 'center',
                //   paddingTop: 10,
                //   paddingBottom: 0,
                //   paddingRight: 0,
                //   paddingLeft: 0,
                //   borderColor: 'red',
                //   borderWidth: 0,
                // }}
                style={{
                  width: '25%',
                  borderWidth: 0,
                  borderColor: 'blue',
                  backgroundColor: colors.white,
                  textAlign: 'center',
                  fontSize: 14,
                }}
                // focusedBorderWidth={0}
                // label={''}
                value={props.stayHospitalDays?.toString()}
                // placeholder={'이름을 입력하세요'}
                // borderColor={theme.font}
                // borderColor={colors.exitBlue}
                // borderWidth={0}
                // focusColor={colors.white}
                placeholderTextColor={colors.black200}
                // value={name}
                onChangeText={(days): void => {
                  if (days.length > 3) return;
                  if (
                    parseInt(days) < 0 ||
                    parseInt(days) > 999 ||
                    isNaN(parseInt(days))
                  ) {
                    updateStayHospitalDays(0);
                  } else {
                    const newDays = parseInt(days);
                    updateStayHospitalDays(newDays);
                  }
                }}
                onFocus={(): void => {
                  // setScrollPadding(-180);
                }}
                onBlur={(): void => {
                  // setScrollPadding(0);
                }}
                // errorText={errorRequestName}
                // errorColor={colors.gloryRed}
                // onSubmitEditing={requestSignUp}
                // type={EditTextInputType.ROW_BOX}
              />
              {/* <TextInput
                style={{
                  borderWidth: 1,
                  borderColor: 'red',
                  // paddingVertical: Platform.OS === 'ios' ? undefined : 0,
                  // paddingHorizontal: Platform.OS === 'ios' ? undefined : 0,
                  lineHeight: 17,
                  textAlignVertical: 'center',
                }}
                // textAlign={'center'}
                underlineColorAndroid="transparent"
                allowFontScaling={true}
                keyboardType="numeric"
                maxLength={3}
                // defaultValue={`${props.stayHospitalDays}`}
                value={(props.stayHospitalDays)?.toString()}
                onChangeText={(days): void => {
                  if (parseInt(days) < 0 || parseInt(days) > 999 || isNaN(parseInt(days))) {
                    updateStayHospitalDays(0);
                  } else {
                    const newDays = parseInt(days);
                    updateStayHospitalDays(newDays);
                  }
                }}
              /> */}

              <TouchableOpacity
                onPress={(): void => {
                  updateStayHospitalDays(
                    props.stayHospitalDays ? props.stayHospitalDays + 1 : 1,
                  );
                }}
                disabled={
                  props.isDisabled ||
                  (!!props.stayHospitalDays && props.stayHospitalDays >= 999)
                }
                style={{
                  backgroundColor:
                    (!!props.stayHospitalDays &&
                      props.stayHospitalDays >= 999) ||
                    props.isDisabled
                      ? colors.black200
                      : colors.black600,
                  width: 30,
                  height: 28,
                  justifyContent: 'center',
                  alignItems: 'center',
                  borderWidth: 0,
                  borderColor: 'red',
                }}
              >
                <Icon
                  name="plus"
                  size={18}
                  color={colors.white}
                  style={{ top: 0.8, zIndex: 1 }}
                />
              </TouchableOpacity>
            </View>
          </View>
        </View>
      </Wrapper>

      <StyledSplitBar style={{ marginTop: 10 }} />

      <Wrapper>
        <View
          style={{
            width: '100%',
            height: 62,
            backgroundColor: '#efefef',
            borderColor: '#dddddd',
            borderRadius: 2,
            borderWidth: 0.7,
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <View
            style={{
              width: '90%',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Text
              style={{
                fontFamily: 'Noto Sans KR',
                fontSize: 14,
              }}
            >
              통원일수
            </Text>
            <View
              style={{
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'space-between',
                width: 126,
                height: 28,
                backgroundColor: 'white',
              }}
            >
              <TouchableOpacity
                onPress={(): void => {
                  updateOnGoingHospitalDays(
                    props.goingHospitalDays ? props.goingHospitalDays - 1 : 0,
                  );
                }}
                disabled={
                  props.isDisabled ||
                  !props.goingHospitalDays ||
                  props.goingHospitalDays === 0
                }
                style={{
                  backgroundColor:
                    !props.goingHospitalDays ||
                    props.goingHospitalDays === 0 ||
                    props.isDisabled
                      ? colors.black200
                      : colors.black600,
                  width: 30,
                  height: 28,
                  justifyContent: 'center',
                  alignItems: 'center',
                  borderWidth: 0,
                  borderColor: 'red',
                }}
              >
                <Icon
                  name="minus"
                  size={18}
                  color={colors.white}
                  style={{ top: 0.8, zIndex: 1 }}
                />
              </TouchableOpacity>

              <EditText
                testID="stay-hospital-days"
                // errorTestID="error-name"
                // textInputProps={{ allowFontScaling: false }}
                allowFontScaling={false}
                // textStyle={{
                //   fontSize: 16,
                //   color: colors.black700,
                //   textAlign: 'center',
                //   textAlignVertical: 'center',
                //   paddingTop: Platform.OS === 'ios' ? 4 : 0,
                //   paddingBottom: 0,
                //   paddingRight: 0,
                //   paddingLeft: 0,
                //   borderColor: 'red',
                //   borderWidth: 0,
                // }}
                style={{
                  width: '25%',
                  borderWidth: 0,
                  borderColor: 'blue',
                  backgroundColor: colors.white,
                  textAlign: 'center',
                  fontSize: 14,
                }}
                // focusedBorderWidth={0}
                // label={''}
                value={props.goingHospitalDays?.toString()}
                // placeholder={'이름을 입력하세요'}
                // borderColor={theme.font}
                // borderColor={colors.white}
                // borderWidth={0}
                // focusColor={colors.white}
                placeholderTextColor={colors.black200}
                // value={name}
                onChangeText={(days): void => {
                  if (days.length > 3) return;
                  if (
                    parseInt(days) < 0 ||
                    parseInt(days) > 999 ||
                    isNaN(parseInt(days))
                  ) {
                    updateOnGoingHospitalDays(0);
                  } else {
                    const newDays = parseInt(days);
                    updateOnGoingHospitalDays(newDays);
                  }
                }}
                onFocus={(): void => {
                  // setScrollPadding(-180);
                }}
                onBlur={(): void => {
                  // setScrollPadding(0);
                }}
                // errorText={errorRequestName}
                // errorColor={colors.gloryRed}
                // onSubmitEditing={requestSignUp}
                // type={EditTextInputType.ROW_BOX}
              />

              <TouchableOpacity
                onPress={(): void => {
                  updateOnGoingHospitalDays(
                    props.goingHospitalDays ? props.goingHospitalDays + 1 : 1,
                  );
                }}
                disabled={
                  props.isDisabled ||
                  (!!props.goingHospitalDays && props.goingHospitalDays >= 999)
                }
                style={{
                  backgroundColor:
                    (!!props.goingHospitalDays &&
                      props.goingHospitalDays >= 999) ||
                    props.isDisabled
                      ? colors.black200
                      : colors.black600,
                  width: 30,
                  height: 28,
                  justifyContent: 'center',
                  alignItems: 'center',
                  borderWidth: 0,
                  borderColor: 'red',
                }}
              >
                <Icon
                  name="plus"
                  size={18}
                  color={colors.white}
                  style={{ top: 0.8, zIndex: 1 }}
                />
              </TouchableOpacity>
            </View>
          </View>
        </View>
      </Wrapper>
    </>
  );
};

export default AcciTreatmentSituation;
