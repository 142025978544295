import AccidentCategoryCard from './AccidentCategoryCard';
import { AccidentCategoryIcons } from '../../../utils/Icons';
import { AccidentCategoryType } from '../../../types';
import React from 'react';
import { colors } from '../../../theme';
import styled from 'styled-components/native';
import { useAppContext } from '../../../providers/AppProvider';

const Wrapper = styled.View`
  width: 100%;
  /* flex-direction: column; */
  /* align-items: center; */
  justify-content: space-between;
  border-width: 0;
  border-color: red;
`;

const CategoryWrapper = styled.View`
  width: 100%;
  height: 60;
  flex-direction: row;
  justify-content: center;
  justify-content: space-between;
  border-width: 0;
  border-color: red;
  /* margin-top: 6; */
`;

interface Props {
  testID?: string;
  title?: string;
  isDisabled?: boolean;
  selectedAccidentCategoryType?: AccidentCategoryType;
  onClick?: () => void;
  onSelectAccidentCategoryType: (selectedAccidentCategoryType: AccidentCategoryType) => void;
}

const AccidentCategoryCards = (props: Props): React.ReactElement => {
  // const [forms, dispatchForms] = React.useReducer(Reducer, initialformsState);
  const { state: { baseAccidentCategories } } = useAppContext();
  // const [selectedAccidentCategoryType, setSelectedAccidentCategoryType] = React.useState<AccidentCategoryType>();

  const onSelectAccidentCategory = (selAccCategoryType: AccidentCategoryType): void => {
    // setSelectedAccidentCategoryType(selAccCategoryType);
    props.onSelectAccidentCategoryType(selAccCategoryType);
  };
  // console.log('>>>>', baseAccidentCategories);
  return (
    <Wrapper>
      <CategoryWrapper>
        <AccidentCategoryCard
          onClick={(): void => {
            onSelectAccidentCategory(AccidentCategoryType.carcar);
          }}
          icon={AccidentCategoryIcons.car2car}
          title={baseAccidentCategories?.carcar?.label}
          isCounseling={true}
          isOn={ props.selectedAccidentCategoryType === AccidentCategoryType.carcar }
          // style={{ marginTop: 20 }}
        />
        <AccidentCategoryCard
          onClick={(): void => {
            onSelectAccidentCategory(AccidentCategoryType.carperson);
          }}
          icon={AccidentCategoryIcons.car2man}
          title={baseAccidentCategories?.carperson?.label}
          isCounseling={true}
          // style={{ marginTop: 20 }}
          isOn={props.selectedAccidentCategoryType === AccidentCategoryType.carperson }
        />

      </CategoryWrapper>

      <CategoryWrapper>
        <AccidentCategoryCard
          onClick={(): void => {
            onSelectAccidentCategory(AccidentCategoryType.carbike);
          }}
          icon={AccidentCategoryIcons.car2bike}
          title={baseAccidentCategories?.carbike?.label}
          isCounseling={true}
          // style={{ marginTop: 20 }}
          isOn={props.selectedAccidentCategoryType === AccidentCategoryType.carbike }
        />
        <AccidentCategoryCard
          onClick={(): void => {
            onSelectAccidentCategory(AccidentCategoryType.etc);
          }}
          icon={AccidentCategoryIcons.car2etc}
          title={baseAccidentCategories?.etc?.label}
          isCounseling={true}
          // style={{ marginTop: 20 }}
          isOn={props.selectedAccidentCategoryType === AccidentCategoryType.etc }

        />
      </CategoryWrapper>
    </Wrapper>
  );
};

export default AccidentCategoryCards;
