import * as apis from '../../apis';

import { ActivityIndicator } from 'react-native-paper';
import KakaoLogin from '../shared/KakaoLogin/KakaoLogin';
import React from 'react';
import { SnsLoginType } from '../../types';
import firebase from 'firebase';
import styled from 'styled-components/native';
import { useAppContext } from '../../providers/AppProvider';

const kakaoLoginKey = 'ee6d3a8341b76986ba628bf221754319';

const StyledTouchable = styled.TouchableOpacity`
  position: absolute;
  right: 20;
  top: 20;
  border-width: 0;
  width: 120;
  height: 30;
  border-color: red;
  align-items: center;
  justify-content: center;
`;

const StyledButton = styled.View`
  background-color: ${({ theme }): string => theme.btnPrimary};
  align-self: center;
  border-radius: 4;
  border-width: 2;
  width: 320;
  height: 52;
  border-color: ${({ theme }): string => theme.btnPrimary};

  align-items: center;
  justify-content: center;
`;

const StyledText = styled.Text`
  font-size: 14;
  font-weight: 600;
  color: white;
`;

const StyledImage = styled.Image`
  width: 24;
  height: 24;
  /* position: absolute; */
  /* left: 16; */
`;

interface Props {
  testID?: string;
}
const gotoHome = (): void => {
  window.location.assign('http://www.sagolink.com');
};

function SignInOut(props: Props): React.ReactElement {
  const { state: { user }, resetUser } = useAppContext();
  const [loggingIn, setLoggingIn] = React.useState<boolean>(false);

  const successKakaoLogin = async (authObj): Promise<void> => {
    setLoggingIn(true);
    try {
      const { response } = authObj;
      const customTokenResponse = await apis.simpleAuthGetCustomToken({
        platform: 'web',
        snsLoginType: SnsLoginType.kakao,
        kakaoAccessToken: authObj.response.access_token,
      });

      // const customTokenResponse = await apis.authGetKakaoFirebaseToken({
      //   kakaoAccessToken: authObj.response.access_token,
      //   platform: 'web',
      // });
      const { customToken } = customTokenResponse.data;
      await apis.signInWithCustomToken(customToken);
    } catch (err) {
      console.error(err);
    } finally {
      setLoggingIn(false);
    }
  };
  const failKakaoLogin = (err): void => {
    console.error(err);
  };
  if (user?.uid) {
    return (
      <StyledTouchable
        testID={props.testID}
        onPress={(): void => {
          console.log('>>>>> 로그아웃 !!!!', user?.uid);
          if (user?.uid) {
            firebase.auth().signOut();
          }
        }}
        style={{ borderWidth: 1, borderColor: 'white', borderRadius: 15 }}
      >
        <StyledText>로그아웃</StyledText>
      </StyledTouchable>
    );
  } else {
    return (
      <KakaoLogin
        jsKey={kakaoLoginKey}
        onSuccess={successKakaoLogin}
        onFailure={failKakaoLogin}
        render={(props): React.ReactElement => {
          return (
            <StyledTouchable
              testID={props.testID}
              onPress={(): void => {
                props.onClick();
              }}
              style={{ borderWidth: 1, borderColor: 'white', borderRadius: 15 }}
            >
              {
                loggingIn ? (
                  <ActivityIndicator/>
                ) : (
                  <StyledText>로그인</StyledText>
                )
              }
            </StyledTouchable>
          );
        }}
      />

    );
  }
}

export default SignInOut;
