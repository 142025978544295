import React, { Fragment } from 'react';
import { Text, View } from 'react-native';

import { AccidentForm } from '../../../types';
import Slider from '@material-ui/core/Slider';
import { colors } from '../../../theme';
import styled from 'styled-components/native';
import { withStyles } from '@material-ui/core/styles';

const ScaleButton = styled.TouchableOpacity`
  width: 1;
  height: 8;
  background-color: #efefef;
`;

const ScaleWrapper = styled.View`
  z-index: 1;
  bottom: 10;
  width: 94.1%;
  flex-direction: row;
  justify-content: space-between;
`;

const Wrapper = styled.View`
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  border-width: 0;
  border-color: red;
  /* padding-left: 20;
  padding-right: 20; */
`;

const iOSBoxShadow =
  '0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.13),0 0 0 1px rgba(0,0,0,0.02)';

const marksArray = (): any => {
  const marks = [];
  for (let index = 0; index <= 10; index++) {
    marks.push({ value: index * 10 });
  }
  return marks;
};

const IOSSlider = withStyles({
  root: {
    color: '#3880ff',
    height: 2,
    // padding: '15px 15px',
    width: '100%',
  },
  thumb: {
    height: 28,
    width: 28,
    backgroundColor: '#fff',
    boxShadow: iOSBoxShadow,
    marginTop: -14,
    marginLeft: -14,
    '&:focus, &:hover, &$active': {
      boxShadow:
        '0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.3),0 0 0 1px rgba(0,0,0,0.02)',
      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        boxShadow: iOSBoxShadow,
      },
    },
  },
  active: {},
  valueLabel: {
    left: 'calc(-50% + 12px)',
    top: -22,
    '& *': {
      background: 'transparent',
      color: '#000',
    },
  },
  track: {
    height: 2,
  },
  rail: {
    height: 2,
    opacity: 0.5,
    backgroundColor: '#bfbfbf',
  },
  mark: {
    backgroundColor: '#bfbfbf',
    height: 8,
    width: 1,
    marginTop: -3,
  },
  markActive: {
    opacity: 1,
    backgroundColor: 'currentColor',
  },
})(Slider);

interface Props {
  testID?: string;
  title?: string;
  isDisabled?: boolean;
  onClick?: () => void;
  selectedAccidentForm?: AccidentForm;
  onValueChange: (value: number) => void;
  onSlidingStart?: () => void; // 슬라이딩 시작 호출
  onSlidingComplete?: () => void;
  // faultValue?: number;
}

const AccidentFault = (props: Props): React.ReactElement => {
  const [value, setValue] = React.useState<number>(50);
  const [numValue, setNumValue] = React.useState<number>(50);

  const Scales = (): React.ReactNode => {
    const rows: React.ReactElement[] = [];
    for (let i = 0; i < 21; i++) {
      rows.push(
        i % 2 === 0 ? (
          <ScaleButton key={i} style={{ height: 8 }} />
        ) : (
          <ScaleButton key={i} style={{ height: 4 }} />
        ),
      );
    }
    return <ScaleWrapper>{rows}</ScaleWrapper>;
  };
  React.useEffect(() => {
    if (props.selectedAccidentForm) {
      setValue(props.selectedAccidentForm.faultRatio);
      setNumValue(props.selectedAccidentForm.faultRatio);
    } else {
      setValue(50);
    }
  }, [props.selectedAccidentForm]);

  const handleChange = (event: any, newValue: number):void => {
    setNumValue(newValue);
  };

  return (
    <Fragment>
      {/* <Wrapper style={{ alignItems: 'center' }}>
        <Slider
          style={{
            width: '100%',
            height: 40,
            zIndex: 2,
            borderColor: 'red',
            borderWidth: 0,
          }}
          step={5}
          value={value}
          minimumValue={0}
          maximumValue={100}
          minimumTrackTintColor="#004fff"
          maximumTrackTintColor="#fff"
          onValueChange={(value): void => {
            // setValue(value);
            setNumValue(value);
            // props.onValueChange(value);
          }}
          onSlidingComplete={(): void => {
            props.onValueChange(value);
            props.onSlidingComplete();
          }}
          onSlidingStart={props.onSlidingStart}
        />
        {Scales()}
      </Wrapper> */}
      <IOSSlider
        aria-label="ios slider"
        valueLabelDisplay="off"
        marks={marksArray()}
        step={5}
        defaultValue={50}
        value={numValue}
        onChange={handleChange}
        onChangeCommitted={(): void => {
          props.onValueChange(numValue);
          // props.onSlidingComplete();
        }}
      />
      <Wrapper
        style={{
          flexDirection: 'row',
          justifyContent: 'space-between',
          bottom: 3,
          marginTop: 7,
        }}
      >
        <View style={{ flexDirection: 'row' }}>
          <Text
            style={{
              fontFamily: 'Noto Sans KR',
              fontSize: 13,
              marginRight: 5,
            }}
          >
            나
          </Text>
          <Text
            style={{
              fontFamily: 'Noto Sans KR',
              fontSize: 13,
              color: '#004fff',
            }}
          >
            {numValue === undefined ? numValue : numValue}
          </Text>
        </View>
        <View style={{ flexDirection: 'row' }}>
          <Text
            style={{
              fontFamily: 'Noto Sans KR',
              fontSize: 13,
              color: '#004fff',
            }}
          >
            {numValue === undefined ? numValue : (100 - numValue)}
          </Text>
          <Text
            style={{
              fontFamily: 'Noto Sans KR',
              fontSize: 13,
              marginLeft: 5,
            }}
          >
            상대방
          </Text>
        </View>
      </Wrapper>
      <Wrapper style={{ marginTop: 10 }}>
        <Text
          style={{
            color: colors.softBlue,
            fontFamily: 'Noto Sans KR',
            fontSize: 12,
            lineHeight: 18,
          }}
        >
          ※ 사고 형태에 따라 과실도표에 있는 기본 과실을 가이드로 제공합니다.
          {'\n'}※ 본인이 예상하는 실제 본인의 과실을 입력하세요.
        </Text>
      </Wrapper>
    </Fragment>
  );
};

export default AccidentFault;
