import React, { ReactElement, useReducer } from 'react';

import Spinner from 'react-native-loading-spinner-overlay';
import { colors } from '../theme';
import createCtx from '../utils/createCtx';

interface Context {
  state: State;
  setGlobalLoading: (loading: boolean, text?: string) => void;
}
const [useCtx, Provider] = createCtx<Context>();

enum ActionType {
  setGlobalLoading = 'set-global-loading',
}

interface State {
  globalLoading: boolean;
  text?: string;
}

const initialState: State = {
  globalLoading: false,
  text: '',
};

interface setGlobalLoadingAction {
  type: ActionType.setGlobalLoading;
  payload: State;
}

type Action = setGlobalLoadingAction;

interface Props {
  children: React.ReactNode;
}

type Reducer = (state: State, action: Action) => State;

const setGlobalLoading = (dispatch: React.Dispatch<setGlobalLoadingAction>) => (
  loading: boolean, text?: string,
): void => {
  dispatch({
    type: ActionType.setGlobalLoading,
    payload: {
      globalLoading: loading,
      text: text,
    },
  });
  setTimeout(() => {
    dispatch({
      type: ActionType.setGlobalLoading,
      payload: {
        globalLoading: false,
      },
    });
  }, 20000);
};
const reducer: Reducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionType.setGlobalLoading:
      return { ...state, ...initialState, ...action.payload };
    default:
      return state;
  }
};

function GlobalLoadingProvider(props: Props): React.ReactElement {
  const [state, dispatch] = useReducer<Reducer>(reducer, initialState);

  const actions = {
    setGlobalLoading: setGlobalLoading(dispatch),
  };
  return <Provider value={{ state, ...actions }}>{props.children}</Provider>;
}

function GlobalLoadingPresenter(): React.ReactElement {
  const { state: { globalLoading, text } } = useCtx();
  return (
    <Spinner
      size={'small'}
      color={'white'}
      overlayColor={'rgba(0,0,0,0.4)'}
      visible={globalLoading}
      textContent={text}
      textStyle={{ fontFamily: 'Noto Sans KR', fontSize: 15, color: colors.white }}
    />
  );
}
export { useCtx as useGlobalLoadingContext, GlobalLoadingProvider, GlobalLoadingPresenter };
