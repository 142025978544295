import {
  ImageSourcePropType,
  ImageStyle,
  TextStyle,
  ViewStyle,
} from 'react-native';
import { ActivityIndicator } from 'react-native-paper';
import { CommonImages } from '../../utils/DesignAssets';

import React from 'react';
import styled from 'styled-components/native';

const StyledTouchable = styled.TouchableOpacity`
  position: absolute;
  left: 20;
  top: 20;
  border-width: 0;
  width: 158;
  height: 30;
  border-color: red;
  align-items: center;
  justify-content: center;
`;

const StyledButton = styled.View`
  background-color: ${({ theme }): string => theme.btnPrimary};
  align-self: center;
  border-radius: 4;
  border-width: 2;
  width: 320;
  height: 52;
  border-color: ${({ theme }): string => theme.btnPrimary};

  align-items: center;
  justify-content: center;
`;

const StyledText = styled.Text`
  font-size: 14;
  font-weight: 600;
  color: ${({ theme }): string => theme.btnPrimaryFont};
`;

const StyledImage = styled.Image`
  width: 24;
  height: 24;
  /* position: absolute; */
  /* left: 16; */
`;

interface Props {
  testID?: string;
}
const gotoHome = (): void => {
  window.location.assign('http://www.sagolink.com');
};

function HomeLink(props: Props): React.ReactElement {
  return (
    <StyledTouchable
      testID={props.testID}
      onPress={(): void => {
        gotoHome();
      }}
    >
      <StyledImage style={{ width: 158, height: 30 }} source={CommonImages.MainLogo} />
    </StyledTouchable>
  );
}

export default HomeLink;
