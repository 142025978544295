import {
  ActivityIndicator,
  ImageSourcePropType,
  ImageStyle,
  TextStyle,
  TouchableOpacity,
  ViewStyle,
} from 'react-native';

import React from 'react';
import { SnsLoginType } from '../../types';
import { signinIcons } from '../../utils/Icons';
import styled from 'styled-components/native';

const StyledButton = styled.TouchableOpacity`
  background-color: ${({ theme }): string => theme.btnPrimary};
  align-self: center;
  border-radius: 12;
  overflow: hidden;
  width: 100%;
  height: 56;
  align-items: center;
  justify-content: center;
`;

const Wrapper = styled.View`
  flex: 1;
  width: 100%;
  height: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: center;

`;
const StyledButtonDisabled = styled(StyledButton)`
  background-color: ${({ theme }): string => theme.btnDisabled};
  border-color: rgb(200, 200, 200);
`;

const StyledText = styled.Text`
  font-family: Noto Sans KR;
  font-size: 18;
  line-height: 26;
  color: ${({ theme }): string => theme.fontColor};
`;

const StyledTextDisabled = styled(StyledText)`
  color: ${({ theme }): string => theme.textDisabled};
`;

const StyledImage = styled.Image`
  width: 100%;
  height: 100%;
`;

interface Props {
  testID?: string;
  onClick?: () => void;
  style?: ViewStyle;
  isDisabled: boolean;
  disabledStyle?: ViewStyle;
  textStyle?: TextStyle;
  imgLeftSrc?: ImageSourcePropType;
  imgLeftStyle?: ImageStyle;
  indicatorColor?: string;
  activeOpacity?: number;
  text?: string;
  snsType: SnsLoginType;
  isLoading?: boolean; // 미구현
}

function LoginButton(props: Props): React.ReactElement | null {
  return (
    props.snsType === SnsLoginType.kakao ? (
      <StyledButton
        testID={props.testID}
        activeOpacity={props.activeOpacity}
        onPress={props.onClick}
        style={props.style}
        disabled={props.isDisabled || props.isLoading}
      >
        <Wrapper style={{ backgroundColor: '#ffdf2b', borderWidth: 0, borderColor: 'red' }}>
          {props.isLoading ? (
            <ActivityIndicator/>
          ) : (
            <>
              <StyledImage style={{ width: 20, height: 18 }} source={ signinIcons.kakaoLogin } resizeMode={'contain'} />
              <StyledText style={{ marginLeft: 8 }}>{'카카오톡으로 로그인'}</StyledText>
            </>
          )}
        </Wrapper>
      </StyledButton>
    ) : props.snsType === SnsLoginType.apple ? (
      <StyledButton
        testID={props.testID}
        activeOpacity={props.activeOpacity}
        onPress={props.onClick}
        style={props.style}
        disabled={props.isDisabled}
      >
        <Wrapper style={{ backgroundColor: '#222222' }}>
          <StyledImage style={{ width: 18, height: 20 }} source={ signinIcons.appleLogin } resizeMode={'contain'} />
          <StyledText style={{ marginLeft: 8, color: 'white' }}>{'Apple로 로그인'}</StyledText>
        </Wrapper>
      </StyledButton>
    ) : null
  );
}

LoginButton.defaultProps = {
  isLoading: false,
  isDisabled: false,
  indicatorColor: 'white',
  activeOpacity: 0.5,
};

export default LoginButton;
