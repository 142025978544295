import {
  FlatList,
  StatusBar,
  StyleSheet,
  View,
} from 'react-native';
import React, { ReactElement } from 'react';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import {
  WoundedDetail,
  WoundedPartType,
} from '../../../types';

import AcciWoundedPartBody from './AcciWoundedPartBody';
import { colors } from '../../../theme';
import { isBrowser } from 'react-device-detect';
import styled from 'styled-components/native';
import { useAppContext } from '../../../providers/AppProvider';

const RowWrapper = styled.SafeAreaView`
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  border-width: 0;
  border-color: red;
  margin-top: 20px;
`;

const DetailCard = styled.TouchableOpacity`
  border-width: 1;
  border-radius: 5;
  border-color: ${colors.black200};
  background-color: ${colors.white};
  width: 132;
  height: 50;
  align-items: center;
  justify-content: center;
  overflow: hidden;
`;

const DetailText = styled.Text`
  font-family: Noto Sans KR;
  font-size: 14;
`;

const WoundedDetailListWrapper = styled.View`
  width: 150px;
  height: 288px;
  background-color: ${colors.black100};
  border-radius: 5px;
  justify-content: center;
  align-items: center;
  padding-top: 10px;
  padding-bottom: 5px;
`;
const WoundedDetailNoText = styled.Text`
  font-family: Noto Sans KR;
  font-size: 14px;
  line-height: 20px;
  color: ${colors.black400};
  text-align: center;
`;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      width: 150,
      height: 295,
      justifyContent: 'center',
      alignItems: 'center',
    },
  }),
);

interface Props {
  selectedWoundedDetails: WoundedDetail[];
  onSelectWoundedDetail: (woundedDetail: WoundedDetail) => void;
  slidingOn: () => void;
  slidingOff: () => void;
}

export default function AcciWoundedBodyNew(props: Props): React.ReactElement {
  const [selectedWoundedPartType, setSelectedWoundedPartType] = React.useState<
    WoundedPartType | undefined
  >(undefined);
  const {
    state: {
      baseWoundedDetails,
    },
  } = useAppContext();

  const woundedDetails =
    selectedWoundedPartType &&
    baseWoundedDetails.filter((detail: WoundedDetail) => {
      return detail.woundedPartType === selectedWoundedPartType;
    });

  const classes = useStyles();
  const flatListRef = React.useRef<FlatList>();
  return (
    <>
      <RowWrapper>
        <AcciWoundedPartBody
          onPressWoundedPart={(woundedPartType: WoundedPartType): void => {
            // 부상부위 선택 시
            setSelectedWoundedPartType(woundedPartType);
            flatListRef.current && flatListRef.current.scrollToOffset({ offset: 0 });
          }}
          selectedWoundedPartType={selectedWoundedPartType}
        />
        <div
          onMouseEnter={isBrowser ? props.slidingOn : null}
          onMouseLeave={isBrowser ? props.slidingOff : null}
          className={classes.wrapper}
        >
          <WoundedDetailListWrapper>
            {woundedDetails ? (
              <FlatList
                ref={flatListRef}
                nestedScrollEnabled={true}
                showsVerticalScrollIndicator={false}
                data={woundedDetails}
                renderItem={({ item, index }): React.ReactElement => (
                  <WoundedDetailCard
                    key={index}
                    onPress={(): void => props.onSelectWoundedDetail(item)}
                    selected={
                      props.selectedWoundedDetails.findIndex(
                        (woundedDetail) => {
                          return woundedDetail.id === item.id;
                        },
                      ) > -1
                    }
                    text={item.label}
                  />
                )}
                keyExtractor={(item, index): string => String(index)}
                ItemSeparatorComponent={(): ReactElement => (
                  <View style={{ height: 10 }} />
                )}
              />
            ) : (
              <WoundedDetailNoText>
                {'선택한 부상부위가\n없습니다.'}
              </WoundedDetailNoText>
            )}
          </WoundedDetailListWrapper>
        </div>
      </RowWrapper>
    </>
  );
}

interface DetailCardProps {
  onPress: () => void;
  selected: boolean;
  text: string;
}
const WoundedDetailCard = (props: DetailCardProps): ReactElement => {
  return (
    <DetailCard
      onPress={props.onPress}
      style={{
        backgroundColor: props.selected ? colors.exitBlue : colors.white,
      }}
    >
      <DetailText
        style={{ color: props.selected ? colors.white : colors.black700 }}
      >
        {props.text}
      </DetailText>
    </DetailCard>
  );
};
